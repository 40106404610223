import { Navigate, Outlet } from "react-router-dom";
import LoginPage from "../Users/Login";
const useAuth = () => {
  const user = { loggedIn: false };
  const userInfo = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  //  console.log("userInfo : ", userInfo);
  return userInfo;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <LoginPage />;
};

export default ProtectedRoutes;
