import React, { useState, useEffect } from "react";
import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const FeedbackDetail = () => {
  const contactUs = useSelector((state) => state.contactUs);

  return (
    <>
      <div className="relative px-4 pb-4">
        <div className="p-6 pt-0 ">
          <Box sx={{ pt: "80px", pb: "20px" }}>
            <Typography variant="h5">Feedback Details</Typography>

            <Grid container spacing={3} className="w-full">
              <Grid className="w-full" sx={{ mt: 4 }}>
                <Paper
                  sx={{
                    boxShadow: "none !important",
                    borderRadius: "12px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "divider",
                    p: "20px",
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <Avatar
                      src=""
                      sx={{ width: "80px", height: "80px", mx: "auto" }}
                    />
                    <Typography variant="h6" sx={{ my: 1 }}>
                      {contactUs?.contactUs?.contsctUs?.full_name || "user"}
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: "15px", mb: 2 }}
                      >
                        Email:{" "}
                        <span style={{ opacity: 0.7 }}>
                          {contactUs?.contactUs?.contsctUs?.email ||
                            "riftvallery@gmail.com"}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: "15px", mb: 2 }}
                      >
                        Phone:{" "}
                        <span style={{ opacity: 0.7 }}>
                          {contactUs?.contactUs?.contsctUs?.phoneNumber ||
                            "+25167532323"}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: "15px", mb: 2 }}
                      >
                        Message:{" "}
                        <span style={{ opacity: 0.7 }}>
                          {contactUs?.contactUs?.contsctUs?.message ||
                            "no feedback"}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default FeedbackDetail;
