import { useDispatch, useSelector } from "react-redux";
import { backend_url } from "../../server";
import React, { useState, useEffect } from "react";

import { viewContent } from "../../States/Action/Content-Action/ContentAction";
import Loading from "../Loading/loading";

const Videos = () => {
  // dispatch
  const dispatch = useDispatch();

  const contents = useSelector((state) => state.contents);
  useEffect(() => {
    dispatch(viewContent());
  }, []);

  const [data, setData] = useState(contents || "");

  useEffect(() => {
    const result = contents?.contents?.contents?.filter((curData) => {
      return curData.content_type === "video";
    });
    setData(result);
  }, [contents]);

  const [isOpen, setIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (video) => {
    setCurrentVideo(video);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Video Gallery</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {data?.length > 0 ? (
            data?.map((video, index) => (
              <div key={index} className="border rounded overflow-hidden">
                <video
                  className="w-full"
                  controls
                  src={`${backend_url}/${video?.media_file}`}
                  poster="https://via.placeholder.com/400x300"
                ></video>
                <button
                  onClick={() => openModal(video)}
                  className="bg-blue-500 text-white w-full py-2"
                >
                  {video?.title}
                </button>
              </div>
            ))
          ) : (
            <Loading />
          )}
        </div>

        {isOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-4 rounded shadow-lg">
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-black"
              >
                X
              </button>
              <video
                className="w-full"
                controls
                src={currentVideo.src}
                autoPlay
              ></video>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Videos;
