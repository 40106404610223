import { Button, Avatar } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineCheck,
  AiOutlineEdit,
} from "react-icons/ai";
import { Link } from "react-router-dom";
// import Loader from "../Layout/Loader";
import { server } from "../../server";
import { FaCheck } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  viewGallery,
  deleteGallery,
  approveGallery,
  singleGallery,
} from "../../States/Action/Gallery-Action/GalleryAction";
import Loading from "../Loading/loading";
import GalleryDetail from "./GalleryDetail";
import GalleryModel from "../../components/popup-model";
import ConfirmationDialog from "../windowsPopUp";

const GalleryList = () => {
  const dispatch = useDispatch();
  // use effect
  useEffect(() => {
    dispatch(viewGallery());
  }, []);

  const [galleryDel, setGalleryDel] = useState();
  const gallery = useSelector((state) => state.gallery);
  const [isDialogOpen, setDialogOpen] = useState(false);
  //console.log(" view all gallery : ", gallery?.gallery?.galleries);

  const handleDelete = async (id) => {
    setDialogOpen(true);
    localStorage.setItem("id", JSON.stringify(id));
  };
  const handleConfirm = () => {
    setDialogOpen(false);
    const id = localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : null;
    dispatch(deleteGallery(id));
    setTimeout(function () {
      dispatch(viewGallery());
    }, 1000);
  };

  const handleApprove = async (data) => {
    const newData = {
      id: data?.id,
      status: "APPROVED",
    };
    if (window.confirm("Are you sure you want to approve this news item?")) {
      dispatch(approveGallery(newData));
      setTimeout(function () {
        dispatch(viewGallery());
      }, 1000);
    }
  };
  const handleGalleryDetail = (id) => {
    if (id !== null) {
      dispatch(singleGallery(id));
      setGalleryDel(true);
    }
  };
  const handleGalleryCancle = () => {
    setGalleryDel(false);
    dispatch(viewGallery());
  };
  const Role = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  function parseJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const userRole = parseJWT(Role);

  const isAdminOrModerator =
    userRole?.role === "admin" || userRole.role === "moderator";
  const isUser = userRole.role === "user";
  const isAdmin = userRole?.role === "admin";

  const columns = [
    {
      field: "gallery", //access nested data with dot notation
      headerName: "Images",
      minWidth: 150,
      flex: 0.2,
      renderCell: (params) => (
        <div>
          <Avatar
            src={`https://backend.riftvalley.org.et/uploads/${params.value}`}
            sx={{ width: 30, height: 30 }}
          />
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      flex: 1.4,
    },
    {
      field: "gallery",
      headerName: "Gallery",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 0.8,
      renderHeader: (params) =>
        params.field === "status" ? "Status" : <FaCheck size={20} />,
      renderCell: (params) => (
        <div
          style={{ color: params.value === "APPROVED" ? "green" : "inherit" }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "posted_by",
      headerName: "Posted By",
      minWidth: 150,
      flex: 0.6,
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      minWidth: 150,
      flex: 0.6,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "dateTime",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      type: "dateTime",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "Actions",
      flex: 0.2,
      minWidth: 250,
      headerName: "",

      sortable: false,
      renderCell: (params) => (
        <div>
          <Button onClick={() => handleGalleryDetail(params.id)}>
            <AiOutlineEye size={20} />
          </Button>

          {isAdminOrModerator && (
            <Link to={`/update-gallery/${params.id}`}>
              <Button>
                <AiOutlineEdit size={20} />
              </Button>
            </Link>
          )}
          {isAdmin && (
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          )}
        </div>
      ),
    },
    isUser == true
      ? ""
      : {
          field: "Approve",
          flex: 0.8,
          minWidth: 120,
          headerName: "",

          sortable: false,
          renderCell: (params) => (
            <>
              {isAdminOrModerator && (
                <div>
                  {params.row.status === "APPROVED" && (
                    <Button
                      style={{
                        color: "#388b84",
                        backgroundColor: "#87CEEB",
                        padding: "4px",
                        border: "50px",
                      }}
                    >
                      {params.row.status}
                    </Button>
                  )}
                  {params.row.status === "PENDING" && (
                    <Button
                      style={{
                        color: "#388b84",
                        backgroundColor: "#388b8433",
                        padding: "4px",
                        border: "50px",
                      }}
                      onClick={() => handleApprove(params.row)}
                    >
                      {params.row.status}
                    </Button>
                  )}
                </div>
              )}
            </>
          ),
        },
  ];

  const rows = gallery?.gallery?.galleries?.map((gallery) => ({
    id: gallery?.id,
    title: gallery?.title,
    description: gallery?.description,
    gallery: gallery?.gallery,
    status: gallery?.status,
    posted_by: gallery?.user?.full_name,
    approved_by: gallery?.users?.full_name,
    createdAt: new Date(gallery?.createdAt),
    updatedAt: new Date(gallery?.updatedAt),
  }));

  return (
    <>
      <div className="w-full  pt-1 mt-10 bg-white text-center">
        <h1 className="text-2xl mb-4">Galleries</h1> {/* Title added */}
        {gallery?.gallery?.galleries ? (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={8}
            disableSelectionOnClick
            autoHeight
          />
        ) : (
          <Loading />
        )}
      </div>
      <GalleryModel
        isVisible={galleryDel}
        onClose={() => handleGalleryCancle()}
      >
        <GalleryDetail />
      </GalleryModel>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to delete this image ?"
      />
    </>
  );
};

export default GalleryList;
