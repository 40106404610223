import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  singleBoardmembers,
  updateBoardmembers,
} from "../../States/Action/Boardmember-Action/BoardmemberAction";

const UpdateBoardMembers = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const gallery = useSelector((state) => state.gallery);
  const boardmember = useSelector((state) => state.boardmember);
  const {
    handleSubmit,
    register,
    reset,
    //formState: { errors },
  } = useForm();

  useEffect(() => {
    onReset();
  }, []);

  const onReset = async () => {
    const result = await dispatch(singleBoardmembers(id));
    reset(result.payload);
  };

  const [image, setImage] = useState(null);

  const [board_member_name, setBoardMembers_name] = useState();
  const [board_member_profession, setBoardMembers_profession] = useState();
  const [board_member_photo, setBoardMembers_photo] = useState();
  const [galleryImg, setGalleryImg] = useState();

  const [filePreviews, setFilePreviews] = useState({
    partners: [],
    values: [],
    programAreas: [],
    boardMembers: [],
    gallery: null,
    content: null,
    publication: null,
  });

  useEffect(() => {
    if (
      boardmember.boardmember?.boardMember?.length !== null &&
      boardmember.boardmember?.boardMember !== undefined
    ) {
      setBoardMembers_name(
        boardmember?.boardmember?.boardMember?.board_member_name
      );
      setBoardMembers_profession(
        boardmember?.boardmember?.boardMember?.board_member_profession
      );
      setGalleryImg(boardmember?.boardmember?.boardMember?.board_member_photo);
    }
  }, [boardmember?.boardmember?.boardMember]);

  const handleFormSubmit = async (e) => {
    const formData = new FormData();
    formData.append("board_member_photo", image);
    formData.append("board_member_name", e.board_member_name);
    formData.append("board_member_profession", e.board_member_profession);
    formData.append("id", id);

    dispatch(updateBoardmembers({ formData: formData, id: id }));
  };

  return (
    <>
      <form
        className="mt-16 ml-8 mr-8"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className="flex justify-center mb-8">
          <label htmlFor="formType" className="text-lg" color="primary">
            Update BoardMembers Data
          </label>
        </div>

        <div className="space-y-4">
          <TextField
            variant="outlined"
            name="board_member_name"
            value={board_member_name}
            {...register(`board_member_name`)}
            onChange={(e) => setBoardMembers_name(e.target.value)}
            fullWidth
            className="my-4"
          />

          <TextField
            variant="outlined"
            name="board_member_profession"
            value={board_member_profession}
            {...register(`board_member_profession`)}
            onChange={(e) => setBoardMembers_profession(e.target.value)}
            multiline
            rows={2}
            fullWidth
            className="my-4"
          />
          <div className="space-x-4">
            <img
              src={`https://backend.riftvalley.org.et/uploads/${galleryImg}`}
              width={90}
            />
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              name="board_member_photo"
              multiple
              className=" lg:ml-20 ml-10 mt-3"
            />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div>
            <button
              className="mt-8 mb-8 bg-blue-600 px-4 py-2 text-white rounded-md"
              type="submit"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateBoardMembers;
