import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./DashboardSideBar";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Header from "./DashboardHeader";

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const decodedUser = token ? jwtDecode(token) : null;
  const userRole = decodedUser ? decodedUser.role : null;

  if (!token) {
    navigate("/login");
    return null;
  }

  return (
    <div className="bg-white">
      <Header />
      <aside
        id="sidebar"
        className="fixed h-full top-[125px] left-0 flex flex-shrink-0 flex-col w-[270px] transition-width duration-75 hidden md:flex"
        aria-label="Sidebar"
      >
        <Sidebar role={userRole} active={location.pathname} />
      </aside>
      <div
        id="main-content"
        className="flex flex-col bg-white relative pt-[125px] lg:ml-[270px]"
      >
        <div className="flex-grow p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
