// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

//List all Content
export const viewContent = createAsyncThunk("contents", async (thunkAPI) => {
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  try {
    let response = await axios.get(`${server}/contents/paginate-dashboard`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Please try again.");
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    toast.error("Please try again. this is Network error");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
//Add new Content
export const createContent = createAsyncThunk(
  "contents",
  async (contentData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.post(
        `${server}/contents/create-content`,
        contentData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 201) {
        toast.success("Content Created successfully!");
        return response.data.contents;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// update Content
export const updateContent = createAsyncThunk(
  "contents",
  async (contentsData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;

    try {
      // response
      let response = await axios.put(
        `${server}/contents/${contentsData.id}`,
        contentsData.formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      // condition
      if (response.status === 200) {
        toast.success("contents data successfuly updated !");
        return response.data.contents;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Search Content
export const deleteContent = createAsyncThunk(
  "contents",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.delete(`${server}/contents/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        toast.success("delete successfull !");
        return response.data.contents;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const singleContent = createAsyncThunk(
  "contents",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(`${server}/contents/dashboard/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//Approve content
export const approveContent = createAsyncThunk(
  "contents",
  async (contentData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      // console.log("approve data from m :", contentData);
      let response = await axios.put(
        `${server}/contents/approve-content/${contentData.id}`,
        contentData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("response : ", response);
      if (response.status === 200) {
        toast.success("Content Data Approved !");
        return response.data.contents;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
