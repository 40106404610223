import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RxAvatar } from "react-icons/rx";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { createUser } from "../../States/Action/User-Action/UserAction";
import {
  Container,
  Grid,
  TextField,
  Button,
  Avatar,
  Typography,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const CreateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userToEdit = location.state?.user;

  const [email, setEmail] = useState(userToEdit ? userToEdit.email : "");
  const [full_name, setName] = useState(userToEdit ? userToEdit.full_name : "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState(userToEdit ? userToEdit.role : "");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(
    userToEdit ? userToEdit.phoneNumber : ""
  );
  const [errMsg, setErrMsg] = useState("");

  // ========== Email Validation start here ==============
  const emailValidation = () => {
    return String(email)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ========== Email Validation end here ================

  useEffect(() => {
    if (userToEdit && userToEdit.avatar) {
      setPreview(userToEdit.avatar);
    }
  }, [userToEdit]);

  const handleFileInputChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setAvatar(null);
      return;
    }
    setAvatar(e.target.files[0]);
  };

  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    data.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please try again.");
      return;
    }

    if (full_name === "") {
      setErrMsg("Username is required!");
    } else if (phoneNumber === "") {
      setErrMsg("Phone number is required!");
    } else if (email === "") {
      setErrMsg("Please give your Email!");
    } else if (!emailValidation(email)) {
      setErrMsg("Give a valid Email!");
    } else {
      const formData = new FormData();
      if (!userToEdit) {
        formData.append("password", password);
      }
      dispatch(
        createUser({
          avatar: avatar,
          full_name: full_name,
          email: email,
          phoneNumber: phoneNumber,
          password: password,
          role: role,
        })
      );
      setTimeout(function () {
        navigate("/users");
      }, 3000);
    }
  };

  return (
    <Container
      maxWidth="md"
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          {userToEdit ? "Update User" : "Register New User"}
        </Typography>
        <form onSubmit={handleSubmit}>
          {errMsg && (
            <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b]  dark:from-[#e1e1e1] dark:to-[#dedcdc] dark:shadow-shadowTwo shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
              {errMsg}
            </p>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="full_name"
                label="Full Name"
                variant="outlined"
                fullWidth
                required
                value={full_name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email address"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  label="Role"
                  required
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="moderator">Moderator</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>

            {!userToEdit && (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    label="Password"
                    type={visible ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setVisible(!visible)}>
                            {visible ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="confirm_password"
                    label="Confirm Password"
                    type={visible ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setVisible(!visible)}>
                            {visible ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} container alignItems="center">
              <Avatar
                src={preview}
                style={{ height: "56px", width: "56px", marginRight: "10px" }}
              >
                {!preview && <RxAvatar />}
              </Avatar>
              <Button
                variant="contained"
                component="label"
                style={{
                  backgroundColor: "#87CEEB",
                  color: "white",
                  "&:hover": { backgroundColor: "#2c2a46" },
                }}
              >
                Upload a file
                <input
                  type="file"
                  name="avatar"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleFileInputChange}
                  hidden
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#87CEEB", marginTop: "20px" }}
              >
                {userToEdit ? "Update" : "Submit"}
              </Button>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="body2">
                Already have an account?{" "}
                <Link to="/login" style={{ color: "#87CEEB" }}>
                  Sign In
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default CreateUser;
