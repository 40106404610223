import React, { useState, useEffect } from "react";
import { server, backend_url } from "../../server"; // Importing backend URL
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
const NewsDetail = () => {
  const contents = useSelector((state) => state.contents);
  return (
    <>
      {/* <div className="relative px-4 pb-4">
        <div className="p-6 pt-0 ">
          <div className="font-display flex flex-col justify-center items-center">
            <h2 className="my-2  font-bold text-secondary text-4xl text-gray-700 text-center">
              Content Detail Page
            </h2>

            <div className="inline-grid grid-cols-1 md:grid-cols-2 gap-x-4">
              <div>
                <img
                  src={`${backend_url}/${contents?.contents?.content?.media_file}`}
                />
              </div>
              <div className="">
                <div className="mb-4 w-full">
                  <span className="block text-lg font-display text-inputColor">
                    Title
                  </span>
                  <p className=" w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                    {" "}
                    {contents?.contents?.content?.title || "title 1"}
                  </p>
                </div>
                <div className="mb-2 w-full">
                  <span className="block text-lg font-display text-inputColor">
                    Description
                  </span>
                  <p className=" w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                    {" "}
                    {contents?.contents?.content?.description ||
                      "description 2"}
                  </p>
                </div>
                <div className="mb-2 w-full">
                  <span className="block text-lg font-display text-inputColor">
                    Location
                  </span>
                  <p className=" w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                    {" "}
                    {contents?.contents?.content?.location || "addis ababa"}
                  </p>
                </div>

                <h1
                  className="inline-block px-2 py-1 mt-6 b text-black font-medium text-lg leading-snug uppercase focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out w-full"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  {contents?.contents?.content?.content_type || "news"}
                </h1>

                <NavLink
                  className="absolute flex right-0 m-2 text-sm text-blue-500"
                  to="https://address.eplusapp.et/"
                >
                  Download
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="relative px-4 pb-4">
        <div className="p-6 pt-0 ">
          <h2 className="my-2  font-bold text-secondary text-4xl text-gray-700 text-center">
            Content Detail Page
          </h2>
          <div class="flex min-h-full items-center justify-center">
            <div class="relative flex w-full max-w-[48rem] flex-col md:flex-row rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
              <div class="relative m-0 w-full md:w-2/5 shrink-0 overflow-hidden rounded-xl rounded-r-none bg-white bg-clip-border text-gray-700">
                <img
                  src={`${backend_url}/${contents?.contents?.content?.media_file}`}
                  alt="image"
                  class="h-full w-full object-cover"
                />
              </div>
              <div class="p-6">
                <span className="block text-lg font-display text-inputColor">
                  Title
                </span>
                <h6 class="mb-4 block font-sans text-base font-semibold uppercase leading-relaxed tracking-normal text-pink-500 antialiased">
                  {contents?.contents?.content?.title || "title 1"}
                </h6>
                <span className="block text-lg font-display text-inputColor">
                  Description
                </span>
                <h4 class="mb-2 block font-sans text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                  {" "}
                  {contents?.contents?.content?.description || "description 2"}
                </h4>
                <span className="block text-lg font-display text-inputColor">
                  Location
                </span>
                <p class="mb-8 block font-sans text-base font-normal leading-relaxed text-gray-700 antialiased">
                  {contents?.contents?.content?.content_type || "news"}
                </p>
                <NavLink
                  className="absolute flex right-0 m-2 text-sm text-blue-500"
                  to="https://address.eplusapp.et/"
                >
                  Download
                </NavLink>
              </div>
            </div>
            <link rel="stylesheet" href="https://riftvalley.org.et/gallery" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetail;
