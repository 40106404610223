import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  viewBoardmembers,
  createBoardmember,
  updateBoardmembers,
  singleBoardmembers,
} from "../../Action/Boardmember-Action/BoardmemberAction";

const boardmemberSlice = createSlice({
  name: "boardmember",
  initialState: {
    boardmember: [],
    boardmember: {},
    loading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateBoardmembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBoardmembers.fulfilled, (state, action) => {
        state.loading = false;
        state.boardmember = action.payload;
        state.success = true;
      })
      .addCase(updateBoardmembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },

  extraReducers: (builder) => {
    builder
      .addCase(createBoardmember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBoardmember.fulfilled, (state, action) => {
        state.loading = false;
        state.boardmember = action.payload;
      })
      .addCase(createBoardmember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewBoardmembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(viewBoardmembers.fulfilled, (state, action) => {
        state.loading = false;
        state.boardmember = action.payload;
      })
      .addCase(viewBoardmembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBoardmembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBoardmembers.fulfilled, (state, action) => {
        state.loading = false;
        state.boardmember = action.payload;
      })
      .addCase(updateBoardmembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleBoardmembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(singleBoardmembers.fulfilled, (state, action) => {
        state.loading = false;
        state.boardmember = action.payload;
      })
      .addCase(singleBoardmembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default boardmemberSlice.reducer;
