// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

//Add new users
export const createUser = createAsyncThunk(
  "users",
  async (userData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.post(`${server}/users/create-user`, userData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("res : ", response);
      if (response.status === 201) {
        toast.success("User Created Successfully");
        return response.data.users;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const loginUser = createAsyncThunk(
  "users",
  async (authData, thunkAPI) => {
    try {
      console.log("authData : ", authData);
      let response = await axios.post(`${server}/users/login-user`, authData);
      console.log("response : ", response);
      if (response.status === 200 && response.data.token) {
        localStorage.setItem("token", JSON.stringify(response.data.token));
        toast.success("Login Success!");
        return response.data;
      } else {
        toast.error("Login failed. Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error(
          "An error occurred while logging in. Please try again later."
        );
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//List all users
export const viewUsers = createAsyncThunk("users", async (thunkAPI) => {
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  try {
    let response = await axios.get(`${server}/users/paginate`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    if (e.response && e.response.data && e.response.data.message) {
      toast.error(e.response.data.message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

//List all users
export const singleusers = createAsyncThunk("users", async (id, thunkAPI) => {
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  try {
    let response = await axios.get(`${server}/users/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 201) {
      return response.data;
    } else {
      toast.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    if (e.response && e.response.data && e.response.data.message) {
      toast.error(e.response.data.message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

// update users
export const updateusers = createAsyncThunk(
  "users",
  async (userData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.put(
        `${server}/users/${userData.id}`,
        userData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("response : ", response);
      // condition
      if (response.status === 201) {
        toast.success("Change successfull!");
        return response.data.users;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Search passeger
export const deleteusers = createAsyncThunk("users", async (id, thunkAPI) => {
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  try {
    let response = await axios.delete(`${server}/users/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 201) {
      toast.success("delete successfull !");
      return response.data.users;
    } else {
      toast.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    if (e.response && e.response.data && e.response.data.message) {
      toast.error(e.response.data.message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
