import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { server } from "../../server";
import { useDispatch, useSelector } from "react-redux";
import {
  singleOrganization,
  updateOrganization,
} from "../../States/Action/Organization-Action/OrganizationAction";
import { useParams } from "react-router-dom";

const UpdateOrganization = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const organizations = useSelector((state) => state.organizations);

  useEffect(() => {
    dispatch(singleOrganization(id));
  }, []);

  const [picture, setPicture] = useState(null);
  const [getImg, setImg] = useState(null);

  const [formType, setFormType] = useState("");
  const [contentType, setContentType] = useState("");

  const [organization, setOrganization] = useState({
    about_company: "",
    description_photo: null,
    mobile_number: "",
    organization_email: "",
    work_phone: "",
    company_moto: "",
    company_mission: "",
    company_vision: "",
    coordination_offices: 0,
    fund_mobilized: 0,
    beneficiaries_reached: 0,
    bank_name: "",
    bank_branch_name: "",
    bank_account_number: "",
    bank_swift_code: "",
    facebook_account: "",
    telegram_account: "",
    twitter_account: "",
    tiktok_account: "",
    instagram_account: "",
    youtube_account: "",
    pobox: "",
    fax: "",
  });
  const [about_company, setAbout_company] = useState();
  const [mobile_number, setMobile_number] = useState();
  const [organization_email, setOrganization_email] = useState();
  const [work_phone, setWork_phone] = useState();
  const [company_moto, setCompany_moto] = useState();
  const [company_mission, setCompany_mission] = useState();
  const [company_vision, setCompany_vision] = useState();

  const [partners, setPartners] = useState([
    { partner_name: "", partner_logo: null },
  ]);
  const [values, setValues] = useState([{ value_name: "", value_icon: null }]);
  const [programAreas, setProgramAreas] = useState([
    { program_area_name: "", program_area_icon: null },
  ]);
  const [boardMembers, setBoardMembers] = useState([
    { board_member_name: "", board_member_photo: null },
  ]);

  const [filePreviews, setFilePreviews] = useState({
    partners: [],
    values: [],
    programAreas: [],
    boardMembers: [],
    gallery: null,
    content: null,
    publication: null,
  });

  useEffect(() => {
    if (
      organizations?.organizations?.organizations?.length !== null &&
      organizations?.organizations?.organizations !== undefined
    ) {
      setAbout_company(
        organizations?.organizations?.organizations?.about_company
      );
      setMobile_number(
        organizations?.organizations?.organizations?.mobile_number
      );
      setOrganization_email(
        organizations?.organizations?.organizations?.organization_email
      );
      setWork_phone(organizations?.organizations?.organizations?.work_phone);
      setCompany_moto(
        organizations?.organizations?.organizations?.company_moto
      );
      setCompany_mission(
        organizations?.organizations?.organizations?.company_mission
      );
      setCompany_vision(
        organizations?.organizations?.organizations?.company_vision
      );
    }
  }, [organizations?.organizations?.organizations]);

  const handleInputChange = (e, index, type) => {
    e.persist();
    const { name, value } = e.target;
    switch (type) {
      case "partners":
        const newPartners = [...partners];
        newPartners[index][name] = value;
        setPartners(newPartners);
        break;
      case "organization":
        setOrganization({ ...organization, [name]: value });
        break;
      case "values":
        const newValues = [...values];
        newValues[index][name] = value;
        setValues(newValues);
        break;
      case "programAreas":
        const newProgramAreas = [...programAreas];
        newProgramAreas[index][name] = value;
        setProgramAreas(newProgramAreas);
        break;
      case "boardMembers":
        const newBoardMembers = [...boardMembers];
        newBoardMembers[index][name] = value;
        setBoardMembers(newBoardMembers);
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleFileChange = (e, index, type) => {
    const files = e.target.files;
    setPicture(URL.createObjectURL(e.target.files[0]));
    setImg(e.target.files[0]);
    const updateStateAndPreview = (
      state,
      previewState,
      fieldName,
      file,
      idx
    ) => {
      const newState = [...state];
      newState[idx][fieldName] = file;
      const url = URL.createObjectURL(file);
      const newPreviews = [...filePreviews[previewState]];
      newPreviews[idx] = { file, url };
      setFilePreviews((prev) => ({ ...prev, [previewState]: newPreviews }));
      return newState;
    };

    switch (type) {
      case "partners":
        setPartners(
          updateStateAndPreview(
            partners,
            "partners",
            "partner_logo",
            files[0],
            index
          )
        );
        break;
      case "values":
        if (files.length === 1) {
          setValues(
            updateStateAndPreview(
              values,
              "values",
              "value_icon",
              files[0],
              index
            )
          );
        } else {
          const updatedValues = [...values];
          Array.from(files).forEach((file, i) => {
            const newIndex = index + i;
            if (updatedValues[newIndex]) {
              updatedValues[newIndex].value_icon = file;
            } else {
              updatedValues.push({ value_icon: file });
            }
            const url = URL.createObjectURL(file);
            const newPreviews = [...filePreviews.values];
            newPreviews[newIndex] = { file, url };
            setFilePreviews((prev) => ({ ...prev, values: newPreviews }));
          });
          setValues(updatedValues);
        }
        break;
      case "programAreas":
        setProgramAreas(
          updateStateAndPreview(
            programAreas,
            "programAreas",
            "program_area_icon",
            files[0],
            index
          )
        );
        break;
      case "boardMembers":
        setBoardMembers(
          updateStateAndPreview(
            boardMembers,
            "boardMembers",
            "board_member_photo",
            files[0],
            index
          )
        );
        break;

      case "organization":
        setOrganization(
          updateStateAndPreview(
            [organization],
            "organization",
            "description_photo",
            files[0],
            0
          )
        );
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleRemoveField = (index, type) => {
    switch (type) {
      case "partners":
        setPartners(partners.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          partners: prev.partners.filter((_, i) => i !== index),
        }));
        break;
      case "values":
        setValues(values.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          values: prev.values.filter((_, i) => i !== index),
        }));
        break;
      case "programAreas":
        setProgramAreas(programAreas.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          programAreas: prev.programAreas.filter((_, i) => i !== index),
        }));
        break;
      case "boardMembers":
        setBoardMembers(boardMembers.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          boardMembers: prev.boardMembers.filter((_, i) => i !== index),
        }));
        break;
      case "organization":
        setOrganization({
          about_company: "",
          description_photo: null,
          mobile_number: "",
          organization_email: "",
          work_phone: "",
          company_moto: "",
          company_mission: "",
          company_vision: "",
          coordination_offices: 0,
          fund_mobilized: 0,
          beneficiaries_reached: 0,
          bank_name: "",
          bank_branch_name: "",
          bank_account_number: "",
          bank_swift_code: "",
          facebook_account: "",
          telegram_account: "",
          twitter_account: "",
          tiktok_account: "",
          instagram_account: "",
          youtube_account: "",
          pobox: "",
          fax: "",
        });
        setFilePreviews((prev) => ({ ...prev, organization: null }));
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleAddField = (type) => {
    if (type === "partners") {
      setPartners([...partners, { partner_name: "", partner_logo: null }]);
    } else if (type === "values") {
      setValues([...values, { value_name: "", value_icon: null }]);
    } else if (type === "programAreas") {
      setProgramAreas([
        ...programAreas,
        { program_area_name: "", program_area_icon: null },
      ]);
    } else if (type === "boardMembers") {
      setBoardMembers([
        ...boardMembers,
        { board_member_name: "", board_member_photo: null },
      ]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let endpoint = "";

    switch (formType) {
      case "organization":
        endpoint = `${server}/organizations/create`;
        break;
      default:
        return;
    }
    if (formType === "organization") {
      const formDataToSend = new FormData();
      Object.keys(organization).forEach((key) => {
        if (organization[key]) formDataToSend.append(key, organization[key]);
      });

      formDataToSend.append("partners", JSON.stringify(partners));
      partners.forEach((partner, index) => {
        if (partner.partner_logo) {
          formDataToSend.append(
            `partner_logo`,
            partner.partner_logo,
            partner.partner_logo.name
          );
        }
      });

      formDataToSend.append("values", JSON.stringify(values));
      values.forEach((value, index) => {
        if (value.value_icon) {
          formDataToSend.append(
            `value_icon`,
            value.value_icon,
            value.value_icon.name
          );
        }
      });

      formDataToSend.append("programAreas", JSON.stringify(programAreas));
      programAreas.forEach((programArea, index) => {
        if (programArea.program_area_icon) {
          formDataToSend.append(
            `program_area_icon`,
            programArea.program_area_icon,
            programArea.program_area_icon.name
          );
        }
      });

      formDataToSend.append("boardMembers", JSON.stringify(boardMembers));
      boardMembers.forEach((boardMember, index) => {
        if (boardMember.board_member_photo) {
          formDataToSend.append(
            `board_member_photo`,
            boardMember.board_member_photo,
            boardMember.board_member_photo.name
          );
        }
      });
      dispatch(updateOrganization(formDataToSend));

      //  dispatch(
      //  createOrganization({
      // about_company: organization.about_company,
      //  description_photo: organization.description_photo,
      //    mobile_number: organization.mobile_number,
      //   organization_email: organization.organization_email,
      //  work_phone: organization.work_phone,
      //   company_moto: organization.company_moto,
      //    company_mission: organization.company_mission,
      //    company_vision: organization.company_vision,
      //  coordination_offices: organization.coordination_offices,
      //  fund_mobilized: organization.fund_mobilized,
      //  beneficiaries_reached: organization.beneficiaries_reached,
      //   bank_name: organization.bank_name,
      //  bank_branch_name: organization.bank_branch_name,
      //  bank_account_number: organization.bank_account_number,
      //  bank_swift_code: organization.bank_swift_code,
      // facebook_account: organization.facebook_account,
      // telegram_account: organization.telegram_account,
      // twitter_account: organization.twitter_account,
      // tiktok_account: organization.tiktok_account,
      // instagram_account: organization.instagram_account,
      // youtube_account: organization.youtube_account,
      //   pobox: organization.pobox,
      //  fax: organization.fax,
      // })
      //  );
    }
  };

  const renderPreview = (preview) => {
    const { file, url } = preview;
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={url}
          alt={file.name}
          className="w-16 h-16 object-cover rounded-md"
        />
      );
    }
    if (file.type.startsWith("video/")) {
      return <video src={url} controls className="w-16 h-16 rounded-md" />;
    }
    if (file.type === "application/pdf") {
      return (
        <object data={url} type="application/pdf" width="100%" height="100px">
          <a href={url} target="_blank" rel="noopener noreferrer">
            View PDF
          </a>
        </object>
      );
    }
    return <p>Unsupported file type</p>;
  };

  return (
    <form className="mt-16 ml-8 mr-8" onSubmit={handleFormSubmit}>
      <div className="flex justify-center mb-8">
        <label htmlFor="formType" className="text-lg" color="primary">
          Organization Detail
        </label>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1 space-y-4">
          <TextField
            //  label="About Company"
            variant="outlined"
            name="about_company"
            value={about_company}
            onChange={(e) => setAbout_company(e.target.value)}
            fullWidth
            multiline
            rows={4}
          />
          <div className="flex items-center space-x-2">
            <input
              type="file"
              name="description_photo"
              onChange={(e) => handleFileChange(e, null, "organization")}
              id="description_photo"
              className="hidden"
            />
            <label htmlFor="description_photo">
              <Button
                variant="contained"
                component="span"
                startIcon={<PhotoCamera />}
                color="primary"
              >
                Upload Description Photo
              </Button>
            </label>
          </div>
          <div>
            {filePreviews.organization && (
              <div>Preview: {renderPreview(filePreviews.organization)}</div>
            )}
          </div>

          {/* {renderPreview(filePreviews.organization)} */}
          <TextField
            // label="Mobile Number"
            variant="outlined"
            name="mobile_number"
            value={mobile_number}
            onChange={(e) => setMobile_number(e.target.value)}
            fullWidth
          />
          <TextField
            //label="Organization Email"
            variant="outlined"
            name="organization_email"
            value={organization_email}
            onChange={(e) => setOrganization_email(e.target.value)}
            fullWidth
          />
          <TextField
            // label="Work Phone"
            variant="outlined"
            name="work_phone"
            value={work_phone}
            onChange={(e) => setWork_phone(e.target.value)}
            fullWidth
          />
          <TextField
            // label="Company Moto"
            variant="outlined"
            name="company_moto"
            value={company_moto}
            onChange={(e) => setCompany_moto(e.target.value)}
            fullWidth
          />
          <TextField
            label="PO Box"
            variant="outlined"
            name="pobox"
            value={organization.pobox}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="Fax"
            variant="outlined"
            name="fax"
            value={organization.fax}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
        </div>

        <div className="col-span-1 space-y-4">
          <TextField
            // label="Company Mission"
            variant="outlined"
            name="company_mission"
            value={company_mission}
            onChange={(e) => setCompany_mission(e.target.value)}
            fullWidth
            multiline
            rows={4}
          />
          <TextField
            // label="Company Vision"
            variant="outlined"
            name="company_vision"
            value={company_vision}
            onChange={(e) => handleInputChange(e.target.value)}
            fullWidth
            multiline
            rows={4}
          />
          <TextField
            label="Coordination Offices"
            variant="outlined"
            name="coordination_offices"
            value={organization.coordination_offices}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
            type="number"
          />
          <TextField
            label="Fund Mobilized"
            variant="outlined"
            name="fund_mobilized"
            value={organization.fund_mobilized}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
            type="number"
          />
          <TextField
            label="Beneficiaries Reached"
            variant="outlined"
            name="beneficiaries_reached"
            value={organization.beneficiaries_reached}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
            type="number"
          />
          <TextField
            label="YouTube Account"
            variant="outlined"
            name="youtube_account"
            value={organization.youtube_account}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="Instagram Account"
            variant="outlined"
            name="instagram_account"
            value={organization.instagram_account}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
        </div>

        <div className="col-span-1 space-y-4">
          <TextField
            label="Bank Name"
            variant="outlined"
            name="bank_name"
            value={organization.bank_name}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="Bank Branch Name"
            variant="outlined"
            name="bank_branch_name"
            value={organization.bank_branch_name}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="Bank Account Number"
            variant="outlined"
            name="bank_account_number"
            value={organization.bank_account_number}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="Bank SWIFT Code"
            variant="outlined"
            name="bank_swift_code"
            value={organization.bank_swift_code}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="Facebook Account"
            variant="outlined"
            name="facebook_account"
            value={organization.facebook_account}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="Telegram Account"
            variant="outlined"
            name="telegram_account"
            value={organization.telegram_account}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="Twitter Account"
            variant="outlined"
            name="twitter_account"
            value={organization.twitter_account}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
          <TextField
            label="TikTok Account"
            variant="outlined"
            name="tiktok_account"
            value={organization.tiktok_account}
            onChange={(e) => handleInputChange(e, 0, "organization")}
            fullWidth
          />
        </div>
        <div className="col-span-1 space-y-4">
          {/* Partners Section */}
          <div className="col-span-3 space-y-4">
            <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
              Add Partners
            </h3>
            {partners.map((partner, index) => (
              <div key={index} className="grid grid-cols-3 gap-4 items-center">
                <TextField
                  label="Partner Name"
                  variant="outlined"
                  name="partner_name"
                  value={partner.partner_name}
                  onChange={(e) => handleInputChange(e, index, "partners")}
                  fullWidth
                />
                <input
                  type="file"
                  name="partner_logo"
                  onChange={(e) => handleFileChange(e, index, "partners")}
                  id={`partner_logo_${index}`}
                  className="hidden"
                />
                <label htmlFor={`partner_logo_${index}`}>
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCamera />}
                    color="primary"
                  >
                    Partner Logo
                  </Button>
                </label>
                {filePreviews.partners[index] && (
                  <div>
                    Preview: {renderPreview(filePreviews.partners[index])}
                  </div>
                )}
                <IconButton
                  onClick={() => handleRemoveField(index, "partners")}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => handleAddField("partners")}
            >
              Add Partner
            </Button>
          </div>

          {/* Board Members Section */}
          <div className="col-span-3 space-y-4">
            <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
              Add Board Members
            </h3>
            {boardMembers.map((boardMember, index) => (
              <div key={index} className="grid grid-cols-3 gap-4 items-center">
                <TextField
                  label="Board Member Name"
                  variant="outlined"
                  name="board_member_name"
                  value={boardMember.board_member_name}
                  onChange={(e) => handleInputChange(e, index, "boardMembers")}
                  fullWidth
                />
                <input
                  type="file"
                  name="board_member_photo"
                  onChange={(e) => handleFileChange(e, index, "boardMembers")}
                  id={`board_member_photo_${index}`}
                  className="hidden"
                />
                <label htmlFor={`board_member_photo_${index}`}>
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCamera />}
                    color="primary"
                  >
                    Member Photo
                  </Button>
                </label>
                {filePreviews.boardMembers[index] && (
                  <div>
                    Preview: {renderPreview(filePreviews.boardMembers[index])}
                  </div>
                )}
                {/* {renderPreview(filePreviews.boardMembers[index], index, 'boardMembers')} */}
                <IconButton
                  onClick={() => handleRemoveField(index, "boardMembers")}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => handleAddField("boardMembers")}
            >
              Add Board Member
            </Button>
          </div>
        </div>

        <div className="col-span-1 space-y-4">
          {/* Programmatic Areas Section */}
          <div className="col-span-3 space-y-4">
            <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
              Add Program Areas
            </h3>
            {programAreas.map((programArea, index) => (
              <div key={index} className="grid grid-cols-3 gap-4 items-center">
                <TextField
                  label="Programmatic Area Name"
                  variant="outlined"
                  name="program_area_name"
                  value={programArea.program_area_name}
                  onChange={(e) => handleInputChange(e, index, "programAreas")}
                  fullWidth
                />
                <input
                  type="file"
                  name="program_area_icon"
                  onChange={(e) => handleFileChange(e, index, "programAreas")}
                  id={`program_area_icon_${index}`}
                  className="hidden"
                />
                <label htmlFor={`program_area_icon_${index}`}>
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCamera />}
                    color="primary"
                  >
                    Program Area Icon
                  </Button>
                </label>
                {filePreviews.programAreas[index] && (
                  <div>
                    Preview: {renderPreview(filePreviews.partners[index])}
                  </div>
                )}
                {/* {renderPreview(filePreviews.programAreas[index], index, 'programAreas')} */}
                <IconButton
                  onClick={() => handleRemoveField(index, "programAreas")}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => handleAddField("programAreas")}
            >
              Add Program Area
            </Button>
          </div>

          {/* Values Section */}
          <div className="col-span-3 space-y-4">
            <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
              Add Values
            </h3>
            {values.map((value, index) => (
              <div key={index} className="grid grid-cols-3 gap-4 items-center">
                <TextField
                  label="Value Name"
                  variant="outlined"
                  name="value_name"
                  value={value.value_name}
                  onChange={(e) => handleInputChange(e, index, "values")}
                  fullWidth
                />
                <input
                  type="file"
                  name="value_icon"
                  onChange={(e) => handleFileChange(e, index, "values")}
                  id={`value_icon_${index}`}
                  className="hidden"
                />
                <label htmlFor={`value_icon_${index}`}>
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCamera />}
                    color="primary"
                  >
                    Add Value Icon
                  </Button>
                </label>
                {filePreviews.values[index] && (
                  <div>
                    Preview: {renderPreview(filePreviews.partners[index])}
                  </div>
                )}
                {/* {renderPreview(filePreviews.values[index], index, 'values')} */}
                <IconButton onClick={() => handleRemoveField(index, "values")}>
                  <RemoveIcon />
                </IconButton>
              </div>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => handleAddField("values")}
            >
              Add Value
            </Button>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center">
        <button
          className="mt-8 mb-8 bg-blue-600 px-4 py-2 text-white rounded-md"
          type="submit"
        >
          Update
        </button>
      </div>
    </form>
  );
};

export default UpdateOrganization;
