import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { Button } from "@material-ui/core";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import Logo from "../../assets/images/logo-image.png";
import { useLocation } from "react-router-dom";
import Sidebar from "./DashboardSideBar";
import { jwtDecode } from "jwt-decode";
import { Dashboard } from "@material-ui/icons";

const DashboardHeader = () => {
  const [showLogout, setShowLogout] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const decodedUser = token ? jwtDecode(token) : null;
  const userRole = decodedUser ? decodedUser.role : null;
  const handleProfileClick = () => {
    setShowLogout(!showLogout);
  };
  const handleMenuClick = () => {
    console.log("click handler");
    setShowMenu(!showMenu);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem("token");
      navigate("/login");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  return (
    <div className="w-full h-[125px] bg-gray-50 shadow fixed top-0 left-0 z-30">
      <div className="flex items-center justify-between px-4 h-full">
        <div>
          <div className="md:flex hidden">
            <Link to="/dashboard">
              <img
                src={Logo}
                alt="logo"
                className="w-[110px] h-[100px] relative ml-8"
              />
            </Link>
          </div>
          <div className="md:hidden flex">
            <svg
              onClick={handleMenuClick}
              xmlns="http://www.w3.org/2000/svg"
              fill="black"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </div>
        </div>
        <div>
          <MdAccountCircle
            size={48}
            color="#87CEEB"
            onClick={handleProfileClick}
            className="cursor-pointer"
          />
        </div>
      </div>
      {showLogout && (
        <div className="flex justify-end pr-8 mt-2">
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
            style={{ backgroundColor: "#87CEEB" }}
          >
            Logout
          </Button>
        </div>
      )}
      {showMenu && (
        <div className="flex justify-start mt-2">
          <aside
            id="sidebar"
            className=" h-full left-0 flex flex-col w-[220px] transition-width duration-75"
            aria-label="Sidebar"
            onClick={handleMenuClick}
          >
            <Sidebar role={userRole} active={location.pathname} />
          </aside>
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;
