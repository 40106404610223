import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  viewContent,
  updateContent,
  singleContent,
  deleteContent,
  createContent,
  approveContent,
} from "../../Action/Content-Action/ContentAction";

const initialState = {
  contents: {},
  contents: [],
  loading: false,
  error: null,
  success: false,
};

const contentsSlice = createSlice({
  reducers: {},
  name: "contents",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createContent.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = action.payload;
      })
      .addCase(createContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewContent.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = action.payload;
      })
      .addCase(viewContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = action.payload;
      })
      .addCase(updateContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteContent.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = action.payload;
      })
      .addCase(deleteContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(singleContent.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = action.payload;
      })
      .addCase(singleContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveContent.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = action.payload;
      })
      .addCase(approveContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default contentsSlice.reducer;
