import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  viewPublication,
  deletePublication,
  singlePublication,
  approvePublication,
} from "../../States/Action/Publication-Action/PublicationAction";
import Loading from "../Loading/loading";
import PublicationModel from "../../components/popup-model";
import PublicationDetail from "./PublicationDetail";
import ConfirmationDialog from "../windowsPopUp";
import { useDemoData } from "@mui/x-data-grid-generator";

const PublicationList = () => {
  const dispatch = useDispatch();
  const [publicationDel, setPublicationDel] = useState();
  const publications = useSelector((state) => state.publications);
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(viewPublication());
  }, []);

  console.log("all publication data : ", publications);
  const handleDelete = (id) => {
    setDialogOpen(true);
    localStorage.setItem("id", JSON.stringify(id));
  };
  const handleConfirm = () => {
    setDialogOpen(false);
    const id = localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : null;
    dispatch(deletePublication(id));
    setTimeout(function () {
      dispatch(viewPublication());
    }, 1000);
  };

  const handleApprove = async (data) => {
    const newData = {
      id: data?.id,
      status: "APPROVED",
    };
    if (window.confirm("Are you sure you want to approve this news item?")) {
      dispatch(approvePublication(newData));
      setTimeout(function () {
        dispatch(viewPublication());
      }, 1000);
    }
  };

  const handlePublicationDetail = (id) => {
    if (id !== null) {
      dispatch(singlePublication(id));
      setPublicationDel(true);
    }
  };
  const handlePublicationCancle = () => {
    setPublicationDel(false);
    dispatch(viewPublication());
  };
  const Role = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  function parseJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const userRole = parseJWT(Role);

  const isAdminOrModerator =
    userRole?.role === "admin" || userRole.role === "moderator";
  const isUser = userRole.role === "user";
  const isAdmin = userRole?.role === "admin";
  const columns = [
    // { field: "id", headerName: "News ID", minWidth: 80, flex: 0.8 },
    {
      field: "publication", //access nested data with dot notation
      headerName: "Image",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <img
            src={`https://backend.riftvalley.org.et/uploads/${params.value}`}
            width={60}
          />
        </div>
      ),
    },
    { field: "title", headerName: "Title", minWidth: 100, flex: 0.8 },
    {
      field: "description",
      headerName: "Description",
      minWidth: 100,
      flex: 1.4,
    },

    { field: "posted_by", headerName: "Posted By", minWidth: 100, flex: 0.8 },
    {
      field: "approved_by",
      headerName: "Approved By",
      minWidth: 100,
      flex: 0.8,
    },
    { field: "createdAt", headerName: "Created At", minWidth: 100, flex: 0.8 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 0.4,
      renderHeader: (params) =>
        params.field === "status" ? "Status" : <FaCheck size={20} />,
      renderCell: (params) => (
        <div
          style={{ color: params.value === "APPROVED" ? "green" : "inherit" }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "Actions",
      flex: 0.2,
      minWidth: 250,
      headerName: "",

      sortable: false,
      renderCell: (params) => (
        <div>
          <Button onClick={() => handlePublicationDetail(params.id)}>
            <AiOutlineEye size={20} />
          </Button>

          {isAdminOrModerator && (
            <Link to={`/update-publication/${params.id}`}>
              <Button>
                <AiOutlineEdit size={20} />
              </Button>
            </Link>
          )}
          {isAdmin && (
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          )}
        </div>
      ),
    },
    isUser == true
      ? ""
      : {
          field: "Approve",
          flex: 0.4,
          minWidth: 120,
          headerName: "",

          sortable: false,
          renderCell: (params) => (
            <>
              {isAdminOrModerator && (
                <div>
                  {params.row.status === "APPROVED" && (
                    <Button
                      style={{
                        color: "#388b84",
                        backgroundColor: "#87CEEB",
                        padding: "4px",
                        border: "50px",
                      }}
                    >
                      {params.row.status}
                    </Button>
                  )}
                  {params.row.status === "PENDING" && (
                    <Button
                      style={{
                        color: "#388b84",
                        backgroundColor: "#388b8433",
                        padding: "4px",
                        border: "50px",
                      }}
                      onClick={() => handleApprove(params.row)}
                    >
                      {params.row.status}
                    </Button>
                  )}
                </div>
              )}
            </>
          ),
        },
  ];
  const rows = publications?.publications?.publications?.map(
    (publications) => ({
      id: publications?.id,
      title: publications?.title,
      description: publications?.description,
      publication: publications?.publication,
      status: publications?.status,
      posted_by: publications?.user?.full_name,
      approved_by: publications?.users?.full_name,
      createdAt: new Date(publications?.createdAt),
      updatedAt: new Date(publications?.updatedAt),
    })
  );

  return (
    <>
      <div className="w-full pt-1 mt-10 bg-white text-center">
        <h1 className="text-2xl mb-4">Publications</h1> {/* Title added */}
        {publications?.publications?.publications ? (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={8}
            disableSelectionOnClick
            autoHeight
          />
        ) : (
          <Loading />
        )}
      </div>
      <PublicationModel
        isVisible={publicationDel}
        onClose={() => handlePublicationCancle()}
      >
        <PublicationDetail />
      </PublicationModel>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to delete this publication content?"
      />
    </>
  );
};

export default PublicationList;
