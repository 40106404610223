import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../components/Toasts/toasts";
import { server } from "../../../server";
import { toast } from "react-toastify";

//List all gallery
export const viewGallery = createAsyncThunk("gallery", async (thunkAPI) => {
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  try {
    let response = await axios.get(`${server}/galleries/paginate-dashboard`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("network error");
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    toast.error("No data received from server");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
export const createGallery = createAsyncThunk(
  "gallery",
  async (galleryData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.post(
        `${server}/galleries/create-gallery`,
        galleryData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        toast.success("Gallery Created successfully!");
        return response.data.gallery;
      } else {
        toast.error("Something went wrong");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error(e.response.data.message);

      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//update gallery
export const updateGallery = createAsyncThunk(
  "gallery",
  async (galleryData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.put(
        `${server}/galleries/${galleryData.id}`,
        galleryData.formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("response : ", response);
      if (response.status === 200) {
        toast.success("Gallery Data Updated!");
        return response.data.gallery;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//single image
export const singleGallery = createAsyncThunk(
  "gallery",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(`${server}/galleries/dashboard/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//single image
export const deleteGallery = createAsyncThunk(
  "gallery",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.delete(`${server}/galleries/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        toast.success("Gallery item deleted successfully");
        return response.data.gallery;
      } else {
        toast.error("network error");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      toast.error(" data can not be deleted");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//approve gallery
export const approveGallery = createAsyncThunk(
  "gallery",
  async (galleryData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.put(
        `${server}/galleries/approve/${galleryData.id}`,
        galleryData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success("Gallery Data is Approved!");
        return response.data.gallery;
      } else {
        toast.error("Something went wrong");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
