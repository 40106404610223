import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  viewOrganization,
  createOrganization,
  updateOrganization,
  singleOrganization,
  deleteOrganization,
  approveOrganization,
} from "../../Action/Organization-Action/OrganizationAction";

const initialState = {
  organizations: {},
  organizations: [],
  loading: false,
  error: null,
  success: false,
};

const organizationsSlice = createSlice({
  reducers: {},
  name: "organizations",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload;
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload;
      })
      .addCase(viewOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload;
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload;
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(singleOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload;
      })
      .addCase(singleOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload;
      })
      .addCase(approveOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default organizationsSlice.reducer;
