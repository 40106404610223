import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { createBoardmember } from "../../States/Action/Boardmember-Action/BoardmemberAction";
import { TextField, Button, IconButton } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

const CreateBoardmembers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [picture, setPicture] = useState(null);
  const [getImg, setImg] = useState(null);

  const [formType, setFormType] = useState("");
  const [boardMembers, setBoardMembers] = useState([
    {
      board_member_name: "",
      board_member_profession: "",
      board_member_photo: null,
    },
  ]);
  const [filePreviews, setFilePreviews] = useState({
    partners: [],
    values: [],
    programAreas: [],
    boardMembers: [],
    gallery: null,
    content: null,
    publication: null,
  });

  const handleFileChange = (e, index, type) => {
    const files = e.target.files;
    setPicture(URL.createObjectURL(e.target.files[0]));
    setImg(e.target.files[0]);
    const updateStateAndPreview = (
      state,
      previewState,
      fieldName,
      file,
      idx
    ) => {
      const newState = [...state];
      newState[idx][fieldName] = file;
      const url = URL.createObjectURL(file);
      const newPreviews = [...filePreviews[previewState]];
      newPreviews[idx] = { file, url };
      setFilePreviews((prev) => ({ ...prev, [previewState]: newPreviews }));
      return newState;
    };

    switch (type) {
      case "boardMembers":
        setBoardMembers(
          updateStateAndPreview(
            boardMembers,
            "boardMembers",
            "board_member_photo",
            files[0],
            index
          )
        );
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };
  const handleInputChange = (e, index, type) => {
    e.persist();
    const { name, value } = e.target;
    switch (type) {
      case "boardMembers":
        const newBoardMembers = [...boardMembers];
        newBoardMembers[index][name] = value;
        setBoardMembers(newBoardMembers);
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleRemoveField = (index, type) => {
    switch (type) {
      case "boardMembers":
        setBoardMembers(boardMembers.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          boardMembers: prev.boardMembers.filter((_, i) => i !== index),
        }));
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleAddField = (type) => {
    if (type === "boardMembers") {
      setBoardMembers([
        ...boardMembers,
        { board_member_name: "", board_member_photo: null },
      ]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    // Object.keys(organization).forEach((key) => {
    //   if (organization[key]) formDataToSend.append(key, organization[key]);
    // });

    formDataToSend.append("boardMembers", JSON.stringify(boardMembers));
    boardMembers.forEach((boardMember, index) => {
      if (boardMember.board_member_photo) {
        formDataToSend.append(
          `board_member_photo`,
          boardMember.board_member_photo,
          boardMember.board_member_photo.name,
          boardMember.board_member_profession
        );
      }
    });

    dispatch(createBoardmember(formDataToSend));
    setTimeout(function () {
      navigate("/boardmembers");
    }, 3000);
  };

  const renderPreview = (preview) => {
    const { file, url } = preview;
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={url}
          alt={file.name}
          className="w-16 h-16 object-cover rounded-md"
        />
      );
    }
    if (file.type.startsWith("video/")) {
      return <video src={url} controls className="w-16 h-16 rounded-md" />;
    }
    if (file.type === "application/pdf") {
      return (
        <object data={url} type="application/pdf" width="100%" height="100px">
          <a href={url} target="_blank" rel="noopener noreferrer">
            View PDF
          </a>
        </object>
      );
    }
    return <p>Unsupported file type</p>;
  };
  return (
    <>
      <form className="mt-16 ml-8 mr-8" onSubmit={handleFormSubmit}>
        {/* Board Members Section */}
        <div className="col-span-3 space-y-4">
          <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
            Add Board Members
          </h3>

          {boardMembers.map((boardMember, index) => (
            <div key={index} className="grid grid-cols-4 gap-4 items-center">
              <TextField
                label="Board Member Name"
                variant="outlined"
                name="board_member_name"
                value={boardMember.board_member_name}
                onChange={(e) => handleInputChange(e, index, "boardMembers")}
                fullWidth
              />
              <TextField
                label="Board Member Profession"
                variant="outlined"
                name="board_member_profession"
                value={boardMember.board_member_profession}
                onChange={(e) => handleInputChange(e, index, "boardMembers")}
                fullWidth
              />
              <input
                type="file"
                name="board_member_photo"
                onChange={(e) => handleFileChange(e, index, "boardMembers")}
                id={`board_member_photo_${index}`}
                className="hidden"
              />
              <label htmlFor={`board_member_photo_${index}`}>
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                  color="primary"
                >
                  Member Photo
                </Button>
              </label>
              {filePreviews.boardMembers[index] && (
                <div>
                  Preview: {renderPreview(filePreviews.boardMembers[index])}
                </div>
              )}
              {/* {renderPreview(filePreviews.boardMembers[index], index, 'boardMembers')} */}
              <IconButton
                onClick={() => handleRemoveField(index, "boardMembers")}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          ))}
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => handleAddField("boardMembers")}
          >
            Add Board Member
          </Button>
        </div>

        <div className="my-4 item-center w-full text-center">
          <button
            class="group relative inline-block overflow-hidden border border-primary px-8 py-3 focus:outline-none focus:ring"
            type="submit"
          >
            <span class="absolute inset-y-0 left-0 w-[0px] bg-[#87CEEB] transition-all group-hover:w-1/2 group-active:primary"></span>
            <span class="absolute inset-y-0 right-0 w-[0px] bg-[#87CEEB] transition-all group-hover:w-1/2 group-active:bg-primary"></span>

            <span class="relative text-lg font-medium text-primary transition-colors group-hover:text-white uppercase font-serif">
              submit
            </span>
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateBoardmembers;
