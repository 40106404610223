import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../Loading/loading";
import ConfirmationDialog from "../windowsPopUp";
import {
  viewBoardmembers,
  deleteBoardmembers,
} from "../../States/Action/Boardmember-Action/BoardmemberAction";

const BoardmemberView = () => {
  const boardmember = useSelector((state) => state.boardmember);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewBoardmembers());
  }, []);

  // console.log("list of boardmembers : ", boardmember);

  const handleDelete = async (id) => {
    setDialogOpen(true);
    localStorage.setItem("id", JSON.stringify(id));
  };
  const handleConfirm = () => {
    setDialogOpen(false);
    const id = localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : null;
    dispatch(deleteBoardmembers(id));
    setTimeout(function () {
      dispatch(viewBoardmembers());
    }, 1000);
  };

  const Role = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  function parseJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const userRole = parseJWT(Role);

  const isAdminOrModerator =
    userRole?.role === "admin" || userRole.role === "moderator";
  const isUser = userRole.role === "user";
  const isAdmin = userRole?.role === "admin";

  const columns = [
    // { field: "id", headerName: "News ID", minWidth: 80, flex: 0.8 },
    {
      field: "board_member_photo", //access nested data with dot notation
      headerName: "Image",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <img
            src={`https://backend.riftvalley.org.et/uploads/${params.value}`}
            width={60}
          />
        </div>
      ),
    },
    {
      field: "board_member_name",
      headerName: "Boarmember Name",
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: "board_member_profession",
      headerName: "Boarmember Profession",
      minWidth: 100,
      flex: 0.8,
    },

    { field: "createdAt", headerName: "Created At", minWidth: 100, flex: 0.8 },

    {
      field: "Actions",
      flex: 0.2,
      minWidth: 250,
      headerName: "",

      sortable: false,
      renderCell: (params) => (
        <div>
          {isAdminOrModerator && (
            <Link to={`/update-BoardMembers/${params.id}`}>
              <Button>
                <AiOutlineEdit size={20} />
              </Button>
            </Link>
          )}
          {isAdmin && (
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          )}
        </div>
      ),
    },
  ];
  const rows = boardmember?.boardmember?.boardmember?.map((boardmember) => ({
    id: boardmember?.id,
    board_member_name: boardmember?.board_member_name,
    board_member_photo: boardmember?.board_member_photo,
    board_member_profession: boardmember?.board_member_profession,
    createdAt: new Date(boardmember?.createdAt),
  }));

  return (
    <>
      <div className="w-full pt-1 mt-10 bg-white text-center">
        <div className="flex items-center justify-between px-8">
          <h1 className="text-2xl  mb-4">Boardmembers List</h1>{" "}
          <Link to={`/create-boardmember`}>
            <Button>Add Boardmember</Button>
          </Link>
        </div>

        {boardmember?.boardmember?.boardmember ? (
          <DataGrid
            rows={boardmember?.boardmember?.boardmember}
            columns={columns}
            pageSize={8}
            disableSelectionOnClick
            autoHeight
          />
        ) : (
          <Loading />
        )}
      </div>

      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to delete this user?"
      />
    </>
  );
};

export default BoardmemberView;
