import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { viewVideo, createVideo, updateVideo, singleVideo, deleteVideo} from "../../Action/Videos-Action/VideosAction";

const initialState = {
  videos: {},
  videos: [],
  loading: false,
  error: null,
  success: false,
};

const videosSlice = createSlice({
  reducers: {},
  name: "videos",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(createVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = action.payload;
      })
      .addCase(createVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = action.payload;
      })
      .addCase(viewVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = action.payload;
      })
      .addCase(updateVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = action.payload;
      })
      .addCase(deleteVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(singleVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = action.payload;
      })
      .addCase(singleVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default videosSlice.reducer;
