import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginUser,
  createUser,
  viewUsers,
} from "../../Action/User-Action/UserAction";

const passegerSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    loading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
        state.success = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },

  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(viewUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(viewUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(singleusers.pending, (state) => {
  //       state.loading = true;
  //       state.error = null;
  //     })
  //     .addCase(singleusers.fulfilled, (state, action) => {
  //       state.loading = false;
  //       state.users = action.payload;
  //     })
  //     .addCase(singleusers.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.error.message;
  //     });
  // },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(registerusers.pending, (state) => {
  //       state.loading = true;
  //       state.error = null;
  //     })
  //     .addCase(registerusers.fulfilled, (state, action) => {
  //       state.loading = false;
  //       state.success = true;
  //       state.users = action.payload;
  //     })
  //     .addCase(registerusers.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.error.message;
  //     });
  // },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(loginTicketer.pending, (state) => {
  //       state.loading = true;
  //       state.error = null;
  //     })
  //     .addCase(loginTicketer.fulfilled, (state, action) => {
  //       state.loading = false;
  //       state.users = action.payload;
  //       state.success = true
  //     })
  //     .addCase(loginTicketer.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.error.message;
  //     });
  // },
});

export default passegerSlice.reducer;
