// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

//Add new boardmember
export const createBoardmember = createAsyncThunk(
  "boardmember",
  async (boardmemberData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.post(
        `${server}/boardmembers/create`,
        boardmemberData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("response : ", response);
      if (response.status === 201) {
        toast.success("boardmember Created Successfully");
        return response.data.boardmember;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//List all boardmember
export const viewBoardmembers = createAsyncThunk(
  "boardmember",
  async (thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;

    try {
      let response = await axios.get(`${server}/boardmembers/fetch-all`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//List all boardmember
export const singleBoardmembers = createAsyncThunk(
  "boardmember",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(`${server}/boardmembers/dashboard/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// update boardmember
export const updateBoardmembers = createAsyncThunk(
  "boardmember",
  async (boardmemberData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.put(
        `${server}/boardmembers/${boardmemberData.id}`,
        boardmemberData.formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("response : ", response);
      // condition
      if (response.status === 200) {
        toast.success("Update Boardmember Info successfull!");
        return response.data.boardmember;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Search passeger
export const deleteBoardmembers = createAsyncThunk(
  "boardmember",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.delete(`${server}/boardmembers/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        toast.success("delete successfull !");
        return response.data.boardmember;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
