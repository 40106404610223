import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import Loading from "../Loading/loading";
import { useDispatch, useSelector } from "react-redux";
import {
  viewOrganization,
  deleteOrganization,
} from "../../States/Action/Organization-Action/OrganizationAction";
import ConfirmationDialog from "../windowsPopUp";

const OrganizationList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewOrganization());
  }, []);
  const organizations = useSelector((state) => state.organizations);
  const [organizationDel, setOrganizationDel] = useState();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const Role = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  function parseJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const userRole = parseJWT(Role);

  const handleDelete = async (id) => {
    setDialogOpen(true);
    localStorage.setItem("id", JSON.stringify(id));
  };
  const handleConfirm = () => {
    setDialogOpen(false);
    const id = localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : null;
    dispatch(deleteOrganization(id));
    setTimeout(function () {
      dispatch(viewOrganization());
    }, 1000);
  };

  const isAdminOrModerator =
    userRole?.role === "admin" || userRole.role === "moderator";
  const isAdminOrUser = userRole?.role === "admin" || userRole.role === "user";
  const isAdmin = userRole?.role === "admin";
  const columns = [
    // { field: "id", headerName: "Organization ID", minWidth: 120, flex: 1 },
    {
      field: "about_company",
      headerName: "About Company",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "description_photo",
      headerName: "Description Photo",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "organization_email",
      headerName: "Organization Email",
      minWidth: 150,
      flex: 1,
    },
    { field: "work_phone", headerName: "Work Phone", minWidth: 120, flex: 1 },
    {
      field: "company_moto",
      headerName: "Company Moto",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "company_mission",
      headerName: "Company Mission",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "company_vision",
      headerName: "Company Vision",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "coordination_offices",
      headerName: "Coordination Offices",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "fund_mobilized",
      headerName: "Fund Mobilized",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "beneficiaries_reached",
      headerName: "Beneficiaries Reached",
      minWidth: 150,
      flex: 1,
    },
    { field: "bank_name", headerName: "Bank Name", minWidth: 120, flex: 1 },
    // {
    //   field: "bank_branch_name",
    //   headerName: "Bank Branch Name",
    //   minWidth: 150,
    //   flex: 1,
    // },
    {
      field: "bank_account_number",
      headerName: "Bank Account Number",
      minWidth: 180,
      flex: 1,
    },
    // {
    //   field: "bank_swift_code",
    //   headerName: "Bank Swift Code",
    //   minWidth: 150,
    //   flex: 1,
    // },
    // { field: "facebook_account", headerName: "Facebook Account", minWidth: 150, flex: 1 },
    // { field: "telegram_account", headerName: "Telegram Account", minWidth: 150, flex: 1 },
    // { field: "twitter_account", headerName: "Twitter Account", minWidth: 150, flex: 1 },
    // { field: "tiktok_account", headerName: "TikTok Account", minWidth: 150, flex: 1 },
    // { field: "instagram_account", headerName: "Instagram Account", minWidth: 150, flex: 1 },
    // { field: "youtube_account", headerName: "YouTube Account", minWidth: 150, flex: 1 },
    //{ field: "pobox", headerName: "PO Box", minWidth: 100, flex: 1 },
    //{ field: "fax", headerName: "Fax", minWidth: 100, flex: 1 },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Link to={`/single-organization/${params.id}`}>
            <Button>
              <AiOutlineEye size={20} />
            </Button>
          </Link>
          {isAdmin && (
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          )}
        </div>
      ),
    },
    // {
    //   field: "Approve",
    //   flex: 0.8,
    //   minWidth: 120,
    //   headerName: "",
    //   type: "number",
    //   sortable: false,
    //   renderCell: (params) => (
    //     <>
    //       {isAdminOrModerator && params.row.status !== "APPROVED" && (
    //         <Button
    //           onClick={() => handleApprove(params.row)}
    //           disabled={params.row.status === "APPROVED"}
    //         >
    //           <AiOutlineCheck size={20} />
    //         </Button>
    //       )}
    //       {isAdminOrUser && (
    //         <Link to={`/update-news/${params.id}`}>
    //           <Button>
    //             <AiOutlineEdit size={20} />
    //           </Button>
    //         </Link>
    //       )}
    //     </>
    //   ),
    // },
  ];

  // Cell: ({ cell, row }) => (
  //   <div>
  //     {row.original.status === "Completed" && (
  //       <span
  //         className="status"
  //         style={{ color: "#388b84", backgroundColor: "#388b8433" }}
  //       >
  //         {cell.getValue()}
  //       </span>
  //     )}
  //     {row.original.status === "Declined" && (
  //       <span
  //         className="status"
  //         style={{ color: "#fd4332", backgroundColor: "#fd433233" }}
  //       >
  //         {cell.getValue()}
  //       </span>
  //     )}
  //   </div>
  // ),

  const rows = organizations?.organizations?.organizations?.map(
    (organizations) => ({
      id: organizations?.id,
      about_company: organizations?.about_company,
      description_photo: organizations?.description_photo,
      mobile_number: organizations?.mobile_number,
      organization_email: organizations?.organization_email,
      status: organizations?.status,
      work_phone: organizations?.work_phone,
      company_moto: organizations?.company_moto,
      company_mission: organizations?.company_mission,
      company_vision: organizations?.company_vision,
      coordination_offices: organizations?.coordination_offices,
      fund_mobilized: organizations?.fund_mobilized,
      beneficiaries_reached: organizations?.beneficiaries_reached,
      bank_name: organizations?.bank_name,
      bank_account_number: organizations?.bank_account_number,
      // posted_by: organizations?.user?.full_name,
      //approved_by: organizations?.users?.full_name,
      createdAt: new Date(organizations?.createdAt),
      updatedAt: new Date(organizations?.updatedAt),
    })
  );
  return (
    <>
      <div className="w-full pt-1 mt-10 bg-white text-center">
        <h1 className="text-2xl mb-4">Organization</h1> {/* Title added */}
        {organizations?.organizations?.organizations ? (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        ) : (
          <Loading />
        )}
      </div>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to delete this organization ?"
      />
    </>
  );
};

export default OrganizationList;
