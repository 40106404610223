import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  singlePublication,
  updatePublication,
} from "../../States/Action/Publication-Action/PublicationAction";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const UpdatePublication = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const publications = useSelector((state) => state.publications);

  const {
    handleSubmit,
    register,
    reset,
    //formState: { errors },
  } = useForm();

  useEffect(() => {
    onReset();
  }, []);

  const onReset = async () => {
    const result = await dispatch(singlePublication(id));
    reset(result.payload);
  };

  const [image, setImage] = useState(null);

  const [contentType, setContentType] = useState("");

  const [publication, setPublication] = useState({
    title: "",
    description: "",
    publication_type: "",
    publication: null,
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [publicationImg, setPublicationImg] = useState();

  const [filePreviews, setFilePreviews] = useState({
    partners: [],
    values: [],
    programAreas: [],
    boardMembers: [],
    gallery: null,
    content: null,
    publication: null,
  });

  useEffect(() => {
    if (
      publications?.publications?.publication?.length !== null &&
      publications?.publications?.publication !== undefined
    ) {
      setTitle(publications?.publications?.publication?.title);
      setDescription(publications?.publications?.publication?.description);
      setPublicationImg(publications?.publications?.publication?.publication);
      // setPhoneNumber(users?.users?.user?.phoneNumber);
    }
  }, [publications?.publications?.publication]);

  const handleInputChange = (e, index, type) => {
    e.persist();
    const { name, value } = e.target;
    switch (type) {
      case "publication":
        setPublication({ ...publication, [name]: value });
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleFormSubmit = async (e) => {
    const formData = new FormData();
    formData.append("publication", image);
    formData.append("title", e.title);
    formData.append("description", e.description);
    formData.append("publication_type", e.contentType);
    formData.append("id", id);

    dispatch(updatePublication({ formData: formData, id: id }));
  };

  const handleContentTypeChange = (e) => {
    const selectedContentType = e.target.value;
    setContentType(selectedContentType);

    // Automatically set the content type based on the selected radio button
    if (selectedContentType === "reports") {
      setPublication((prevContent) => ({
        ...prevContent,
        publication_type: "report",
      }));
    } else if (selectedContentType === "researches") {
      setPublication((prevContent) => ({
        ...prevContent,
        publication_type: "researches",
      }));
    }
  };

  return (
    <form className="mt-16 ml-8 mr-8" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex justify-center mb-8">
        <label htmlFor="formType" className="text-lg" color="primary">
          Publication
        </label>
      </div>

      <div className="space-y-4">
        <div className="flex space-x-4">
          <RadioGroup
            aria-label="publication-type"
            name="publication-type"
            value={contentType}
            onChange={handleContentTypeChange}
          >
            <FormControlLabel
              value="reports"
              control={<Radio color="primary" />}
              label="Reports"
            />
            <FormControlLabel
              value="researches"
              control={<Radio color="primary" />}
              label="Researches"
            />
          </RadioGroup>
        </div>
        <TextField
          variant="outlined"
          name="title"
          value={title}
          {...register(`title`)}
          // onChange={(e) => handleInputChange(e, 0, "gallery")}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          className="my-4"
        />

        <TextField
          // label="Description"
          variant="outlined"
          name="description"
          value={description}
          {...register(`description`)}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
          fullWidth
          className="my-4"
        />
        <div className="space-x-4">
          <img
            src={`https://backend.riftvalley.org.et/uploads/${publicationImg}`}
            width={90}
          />
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            name="publication"
            multiple
            className=" lg:ml-20 ml-10 mt-3"
          />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div>
          <button
            className="mt-8 mb-8 bg-blue-600 px-4 py-2 text-white rounded-md"
            type="submit"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default UpdatePublication;
