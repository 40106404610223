import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import publicationImg from "../../assets/images/Projects/Shelter.jpg";
import { NavLink } from "react-router-dom";
import pdfImage from "../../assets/images/Projects/pdfimage.jpeg";

const PublicationDetail = () => {
  const dispatch = useDispatch();
  const publications = useSelector((state) => state.publications);
  //console.log("single publication :", publications);

  return (
    <>
      <div className="relative px-4 pb-4">
        <div className="p-6 pt-0 ">
          <div className="font-display flex flex-col justify-center items-center">
            <h2 className="my-2  font-bold text-secondary text-4xl text-gray-700 text-center">
              Publication Detail Page
            </h2>
            <div className="inline-grid grid-cols-1 md:grid-cols-2 gap-x-4">
              <div>
                <img src={pdfImage} />
              </div>
              <div className="">
                <div className="mb-4 w-full">
                  <span className="block text-lg font-display text-inputColor">
                    Title
                  </span>
                  <p className=" w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                    {" "}
                    {publications?.publications?.publication?.title ||
                      "title 1"}
                  </p>
                </div>
                <div className="mb-2 w-full">
                  <span className="block text-lg font-display text-inputColor">
                    Description
                  </span>
                  <p className=" w-full px-4 py-2 text-base text-gray-700 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                    {" "}
                    {publications?.publications?.publication?.description ||
                      " description 2"}
                  </p>
                </div>

                <h1
                  className="inline-block px-2 py-1 mt-6 b text-black font-medium text-lg leading-snug uppercase focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary active:shadow-lg transition duration-150 ease-in-out w-full"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  {publications?.publications?.publication?.publication_type ||
                    "Researchs"}
                </h1>

                <NavLink
                  className="absolute flex right-0 m-2 text-sm text-blue-500"
                  to="https://address.eplusapp.et/"
                >
                  Download
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PublicationDetail;
