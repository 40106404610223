import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  singleContent,
  updateContent,
  viewContent,
} from "../../States/Action/Content-Action/ContentAction";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const UpdateNews = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const contents = useSelector((state) => state.contents);

  const {
    handleSubmit,
    register,
    reset,
    //formState: { errors },
  } = useForm();

  useEffect(() => {
    onReset();
  }, []);

  const onReset = async () => {
    const result = await dispatch(singleContent(id));
    reset(result.payload);
  };

  //console.log("view single contents : ", contents?.contents?.content);

  const [contentType, setContentType] = useState("");

  const [content, setContent] = useState({
    title: "",
    description: "",
    media_file: null,
    video: null,
    content_type: "",
    location: "",
  });

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [media_file, setMedia_file] = useState("");

  const [filePreviews, setFilePreviews] = useState({
    partners: [],
    values: [],
    programAreas: [],
    boardMembers: [],
    gallery: null,
    content: null,
    publication: null,
  });

  useEffect(() => {
    if (
      contents?.contents?.content?.length !== null &&
      contents?.contents?.content !== undefined
    ) {
      setTitle(contents?.contents?.content?.title);
      setDescription(contents?.contents?.content?.description);
      setLocation(contents?.contents?.content?.location);
      setMedia_file(contents?.contents?.content?.media_file);
    }
  }, [contents?.contents?.content]);
  const [image, setImage] = useState(null);
  const handleFormSubmit = async (e) => {
    //   console.log("E : ", e.content);
    //  e.preventDefault();
    // if (image !== null) {
    const formData = new FormData();
    formData.append("media_file", image);
    formData.append("title", e.title);
    formData.append("description", e.description);
    formData.append("location", e.location);

    formData.append("content_type", e.contentType);
    formData.append("id", id);
    dispatch(updateContent({ formData: formData, id: id }));
    // } else {
    //   const formData = new FormData();
    //   formData.append("media_file", "xyz");
    //   formData.append("title", e.title);
    //   formData.append("description", e.description);
    //   formData.append("location", e.location);

    //   formData.append("content_type", e.contentType);
    //   formData.append("id", id);
    //   dispatch(updateContent({ formData: formData, id: id }));
    // }
  };

  const handleContentTypeChange = (e) => {
    const selectedContentType = e.target.value;
    setContentType(selectedContentType);

    // Automatically set the content type based on the selected radio button
    if (selectedContentType === "video") {
      setContent((prevContent) => ({
        ...prevContent,
        content_type: "video",
      }));
    } else if (selectedContentType === "news") {
      setContent((prevContent) => ({
        ...prevContent,
        content_type: "news",
      }));
    }
  };

  return (
    <form className="mt-16 ml-8 mr-8" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex justify-center mb-8">
        <label htmlFor="formType" className="text-lg" color="primary">
          Contents
        </label>
      </div>
      <div className="space-y-4">
        <div className="flex space-x-4">
          <RadioGroup
            aria-label="content-type"
            name="content_type"
            value={contentType}
            onChange={handleContentTypeChange}
          >
            <FormControlLabel
              value="video"
              control={<Radio color="primary" />}
              label="Video"
            />
            <FormControlLabel
              value="news"
              control={<Radio color="primary" />}
              label="News"
            />
          </RadioGroup>
        </div>
        <TextField
          //label="Title"
          variant="outlined"
          name="title"
          value={title}
          {...register(`title`)}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          //  disabled={!contentType}
          className="my-4"
        />
        <TextField
          //  label="Description"
          variant="outlined"
          name="description"
          value={description}
          {...register(`description`)}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
          fullWidth
          //  disabled={!contentType}
          className="my-4"
        />
        <TextField
          //  label="Location"
          variant="outlined"
          name="location"
          value={location}
          {...register("location")}
          onChange={(e) => setLocation(e.target.value)}
          fullWidth
          // disabled={!contentType}
          className="my-4"
        />
        <div className="space-x-4">
          <img
            src={`https://backend.riftvalley.org.et/uploads/${media_file}`}
            width={90}
          />
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            name="media_file"
            multiple
            className=" lg:ml-20 ml-10 mt-3"
          />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div>
          <button
            className="mt-8 mb-8 bg-blue-600 px-4 py-2 text-white rounded-md"
            type="submit"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default UpdateNews;
