import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  viewContactUs,
  createContactUs,
  updateContactUs,
  singleContactUs,
  deleteContactUs,
} from "../../Action/Contactus-Action/ContactusAction";

const initialState = {
  contactUs: {},
  contactUs: [],
  loading: false,
  error: null,
  success: false,
};

const contactUsSlice = createSlice({
  reducers: {},
  name: "contactUs",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createContactUs.pending, (state) => {
        state.loading = true;
      })
      .addCase(createContactUs.fulfilled, (state, action) => {
        state.loading = false;
        state.contactUs = action.payload;
      })
      .addCase(createContactUs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewContactUs.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewContactUs.fulfilled, (state, action) => {
        state.loading = false;
        state.contactUs = action.payload;
      })
      .addCase(viewContactUs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateContactUs.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateContactUs.fulfilled, (state, action) => {
        state.loading = false;
        state.contactUs = action.payload;
      })
      .addCase(updateContactUs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteContactUs.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteContactUs.fulfilled, (state, action) => {
        state.loading = false;
        state.contactUs = action.payload;
      })
      .addCase(deleteContactUs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleContactUs.pending, (state) => {
        state.loading = true;
      })
      .addCase(singleContactUs.fulfilled, (state, action) => {
        state.loading = false;
        state.contactUs = action.payload;
      })
      .addCase(singleContactUs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default contactUsSlice.reducer;
