import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Loading from "../Loading/loading";
import { useDispatch, useSelector } from "react-redux";
import {
  viewContactUs,
  deleteContactUs,
  singleContactUs,
} from "../../States/Action/Contactus-Action/ContactusAction";
import FeedbackModel from "../../components/popup-model";
import FeedbackDetail from "./FeedbackDetail";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  ListItemButton,
  Tooltip,
  Typography,
  Paper,
  Grid,
} from "@mui/material";

import { FaEllipsisV } from "react-icons/fa";
import ConfirmationDialog from "../windowsPopUp";

const FeedBackList = () => {
  const dispatch = useDispatch();

  const [feedbackDel, setFeedbackDel] = useState();
  const contactUs = useSelector((state) => state.contactUs);
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(viewContactUs());
  }, []);
  const handleFeedbacknDetail = (id) => {
    if (id !== null) {
      dispatch(singleContactUs(id));
      setFeedbackDel(true);
    }
  };
  const handleContactusCancle = () => {
    setFeedbackDel(false);
    dispatch(viewContactUs());
  };

  const handleDelete = async (id) => {
    setDialogOpen(true);
    localStorage.setItem("id", JSON.stringify(id));
  };
  const handleConfirm = () => {
    setDialogOpen(false);
    const id = localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : null;
    dispatch(deleteContactUs(id));
    setTimeout(function () {
      dispatch(viewContactUs());
    }, 1000);
  };

  const columns = [
    { field: "email", headerName: "Email", minWidth: 130, flex: 0.7 },
    { field: "phone", headerName: "Phone", minWidth: 130, flex: 0.8 },
    { field: "message", headerName: "Message", minWidth: 130, flex: 0.8 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <div>
            <Button onClick={() => handleFeedbacknDetail(id)}>
              <AiOutlineEye size={20} />
            </Button>
            <Button onClick={() => handleDelete(id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </div>
        );
      },
    },
  ];
  const dummyMessage =
    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Earum, hic.";
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  }));

  return (
    <>
      <FeedbackModel
        isVisible={feedbackDel}
        onClose={() => handleContactusCancle()}
      >
        <FeedbackDetail />
      </FeedbackModel>

      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRightWidth: "1px",
                  borderRightColor: "divider",
                }}
              >
                <Typography variant="h6">Messages</Typography>
                <Tooltip title="More">
                  <IconButton sx={{ fontSize: "17px", color: "text.primary" }}>
                    <FaEllipsisV />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  borderRightWidth: "1px",
                  borderRightColor: "divider",
                  height: { xs: "fit-content", sm: "60vh" },
                  overflow: "auto",
                }}
                className="hide-scrollbar"
              >
                <Box
                  sx={{
                    display: { xs: "flex", sm: "block" },
                    gap: 2,
                  }}
                >
                  {contactUs?.contactUs?.contacts
                    ? contactUs?.contactUs?.contacts.map((feedback, index) => (
                        <ListItemButton
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          key={index}
                          onClick={() => handleFeedbacknDetail(feedback?.id)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              variant="dot"
                            >
                              <Avatar
                                src=""
                                sx={{ width: "30px", height: "30px" }}
                              />
                            </StyledBadge>

                            <Box sx={{ display: { xs: "none", sm: "block" } }}>
                              <Typography>{feedback?.full_name}</Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ opacity: 0.8 }}
                              >
                                {feedback?.message?.slice(0, 20)}...
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ opacity: 0.8 }}
                            >
                              11:12 AM
                            </Typography>
                            <IconButton
                              sx={{
                                width: "16px",
                                height: "16px",
                                backgroundColor: "#fc424a !important",
                                color: "#fff",
                                fontSize: "10px",
                                float: "right",
                              }}
                            >
                              {Math.floor(Math.random() * 10)}
                            </IconButton>
                          </Box>
                        </ListItemButton>
                      ))
                    : null}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={8}>
              <div className="w-full pt-1 mt-10 bg-white text-center">
                <h1 className="text-2xl mb-4">Feedbacks</h1>
                {contactUs?.contactUs?.contacts ? (
                  <DataGrid
                    rows={contactUs?.contactUs?.contacts}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    autoHeight
                  />
                ) : (
                  <Loading />
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to delete this feedback?"
      />
    </>
  );
};

export default FeedBackList;
