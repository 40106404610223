import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  viewPublication,
  createPublication,
  updatePublication,
  singlePublication,
  deletePublication,
  approvePublication,
} from "../../Action/Publication-Action/PublicationAction";

const initialState = {
  publications: {},
  publications: [],
  loading: false,
  error: null,
  success: false,
};

const publicationsSlice = createSlice({
  reducers: {},
  name: "publications",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createPublication.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPublication.fulfilled, (state, action) => {
        state.loading = false;
        state.publications = action.payload;
      })
      .addCase(createPublication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewPublication.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewPublication.fulfilled, (state, action) => {
        state.loading = false;
        state.publications = action.payload;
      })
      .addCase(viewPublication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePublication.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePublication.fulfilled, (state, action) => {
        state.loading = false;
        state.publications = action.payload;
      })
      .addCase(updatePublication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePublication.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePublication.fulfilled, (state, action) => {
        state.loading = false;
        state.publications = action.payload;
      })
      .addCase(deletePublication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },

  extraReducers: (builder) => {
    builder
      .addCase(approvePublication.pending, (state) => {
        state.loading = true;
      })
      .addCase(approvePublication.fulfilled, (state, action) => {
        state.loading = false;
        state.publications = action.payload;
      })
      .addCase(approvePublication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(singlePublication.pending, (state) => {
        state.loading = true;
      })
      .addCase(singlePublication.fulfilled, (state, action) => {
        state.loading = false;
        state.publications = action.payload;
      })
      .addCase(singlePublication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default publicationsSlice.reducer;
