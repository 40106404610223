// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

//List all ContactUs
export const viewContactUs = createAsyncThunk("contactUs", async (thunkAPI) => {
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  try {
    let response = await axios.get(`${server}/contactus/paginate`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Please try again.");
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    toast.error("Please try again. this is Network error");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
//Add new ContactUs
export const createContactUs = createAsyncThunk(
  "contactUs",
  async (userData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.post(
        `${server}/contactus/create-contactus`,
        userData
      );
      if (response.status === 201) {
        toast.success("Form submitted successfully!");
        return response.data.contactUs;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// update ContactUs
export const updateContactUs = createAsyncThunk(
  "contactUs",
  async (contactUsData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      // response
      let response = await axios.put(
        `${server}/contactUs/${contactUsData?.contactUsId}`,
        contactUsData
      );
      // condition
      if (response.status === 200) {
        toast.success("contactUs data successfuly updated !");
        return response.data.contactUs;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Search ContactUs
export const deleteContactUs = createAsyncThunk(
  "contactUs",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.delete(`${server}/contactus/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        toast.success("delete successfull !");
        return response.data.contactUs;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const singleContactUs = createAsyncThunk(
  "contactUs",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(`${server}/contactus/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
