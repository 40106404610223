import { configureStore } from "@reduxjs/toolkit";
import GalleryReducer from "../States/Reducer/Gallery-Reducer/GalleryReducer";
import UserReducer from "../States/Reducer/User-Reducer/UserReducer";
import ContactusReducer from "../States/Reducer/Contactus-Reducer/ContactusReducer";
import OrganizationReducer from "../States/Reducer/Organization-Reducer/OrganizationReducer";
import VideosReducer from "../States/Reducer/Videos-Reducer/VideosReducer";
import PublicationReducer from "../States/Reducer/Publication-Reducer/PublicationReducer";
import ContentReducer from "../States/Reducer/Content-Reducer/ContentReducer";
import BoardmemberReducer from "../States/Reducer/Boardermember-Reducer/BoardmemberReducer";

const store = configureStore({
  reducer: {
    gallery: GalleryReducer,
    users: UserReducer,
    contactUs: ContactusReducer,
    contents: ContentReducer,
    organizations: OrganizationReducer,
    videos: VideosReducer,
    publications: PublicationReducer,
    boardmember: BoardmemberReducer,
  },
});

export default store;
