// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

//List all Publication
export const viewPublication = createAsyncThunk(
  "publications",
  async (thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(
        `${server}/publications/paginate-dashboard`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//Add new Publication
export const createPublication = createAsyncThunk(
  "publications",
  async (publicationData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      console.log("publication data : ", publicationData);
      let response = await axios.post(
        `${server}/publications/create-publication`,
        publicationData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      //console.log("response : ", response);
      if (response.status === 201) {
        toast.success("Form submitted successfully!");
        return response.data.publications;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// update Publication
export const updatePublication = createAsyncThunk(
  "publications",
  async (publicationsData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      console.log("update publication data : ", publicationsData);
      // response
      let response = await axios.put(
        `${server}/publications/${publicationsData.id}`,
        publicationsData.formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // condition
      if (response.status === 200) {
        toast.success("publications data successfuly updated !");
        return response.data.publications;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Search Publication
export const deletePublication = createAsyncThunk(
  "publications",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.delete(`${server}/publications/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        toast.success("delete successfull !");
        return response.data.publications;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const singlePublication = createAsyncThunk(
  "publications",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(`${server}/publications/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//Approve publication
export const approvePublication = createAsyncThunk(
  "publications",
  async (publicationData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.put(
        `${server}/publications/approve/${publicationData.id}`,
        publicationData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Approved successfully!");
        return response.data.publications;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
