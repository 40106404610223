import React from "react";
import UserListIcon from "@material-ui/icons/Group";
import PeopleIcon from "@material-ui/icons/People";
import {
  Dashboard,
  PostAdd,
  AccountCircle,
  Business,
  LibraryBooks,
  PhotoLibrary,
  Feedback,
  VideoLibrary,
} from "@material-ui/icons";
import SidebarItem from "./SideBarItem"; // Assuming SidebarItem is in the same directory

const DashboardSideBar = ({ active, role }) => {
  const [open, setOpen] = React.useState(false);
  // const currentPath = useLocation().pathname;

  return (
    <div className="w-full bg-white shadow-sm overflow-y-auto sticky top-[200px] left-0 z-10 mt-4 h-[calc(100vh - 200px)]">
      {" "}
      {/* Adjusted styles */}
      {role === "admin" && (
        <>
          <SidebarItem
            to="/dashboard"
            icon={Dashboard}
            label="Dashboard"
            active={active === 1}
            role={role}
          />
          <SidebarItem
            to="/create-user"
            icon={AccountCircle}
            label="Create User"
            active={active === 3}
            role={role}
          />
          <SidebarItem
            to="/users"
            icon={UserListIcon}
            label="Users"
            active={active === 4}
            role={role}
          />
          <SidebarItem
            to="/organizations"
            icon={Business}
            label="Organization"
            active={active === 5}
            role={role}
          />
          <SidebarItem
            to="/feedbacks"
            icon={Feedback}
            label="Feedbacks"
            active={active === 10}
            role={role}
          />
          <SidebarItem
            to="/boardmembers"
            icon={AccountCircle}
            label="BoardMembers"
            active={active === 11}
            role={role}
          />
        </>
      )}
      {(role === "user" || role === "admin" || role === "moderator") && (
        <>
          {(role === "user" || role === "moderator") && (
            <SidebarItem
              to="/dashboard"
              icon={Dashboard}
              label="Dashboard"
              active={active === 1}
              role={role}
            />
          )}
          <SidebarItem
            to="/create-content"
            icon={PostAdd}
            label="Create Content"
            active={active === 2}
            role={role}
          />

          <SidebarItem
            to="/publications"
            icon={PeopleIcon}
            label="Publications"
            active={active === 6}
            role={role}
          />
          <SidebarItem
            to="/news"
            icon={LibraryBooks}
            label="News"
            active={active === 8}
            role={role}
          />
          <SidebarItem
            to="/videos"
            icon={VideoLibrary}
            label="Videos"
            active={active === 9}
            role={role}
          />
          <SidebarItem
            to="/galleries"
            icon={PhotoLibrary}
            label="Galleries"
            active={active === 7}
            role={role}
          />
        </>
      )}
    </div>
  );
};

export default DashboardSideBar;
