import React, { useState, useEffect } from "react";
import { server, backend_url } from "../../server"; // Importing backend URL
import { useDispatch, useSelector } from "react-redux";

const GalleryDetail = () => {
  const gallery = useSelector((state) => state.gallery);
  console.log("single gallery : ", gallery);
  return (
    <>
      <div className="relative px-4 pb-4">
        <div className="p-6 pt-0 ">
          <div class="flex min-h-full items-center justify-center">
            <div class="relative flex w-full max-w-[48rem] flex-col md:flex-row rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
              <div class="relative m-0 w-full md:w-2/5 shrink-0 overflow-hidden rounded-xl rounded-r-none bg-white bg-clip-border text-gray-700">
                <img
                  src={`${backend_url}/${gallery?.gallery?.gallery?.gallery}`}
                  alt="image"
                  class="h-full w-full object-cover"
                />
              </div>
              <div class="p-6">
                <h6 class="mb-4 block font-sans text-base font-semibold uppercase leading-relaxed tracking-normal text-pink-500 antialiased">
                  {gallery?.gallery?.galley?.location || "Addis Ababa"}
                </h6>
                <h4 class="mb-2 block font-sans text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                  {gallery?.gallery?.galley?.title || "Addis Ababa"}
                </h4>
                <p class="mb-8 block font-sans text-base font-normal leading-relaxed text-gray-700 antialiased">
                  {gallery?.gallery?.galley?.description ||
                    "Like so many organizations these days, Autodesk is a company in transition. It was until recently a traditional boxed software company selling licenses. Yet its own business model disruption is only part of the story"}
                </p>
                <a class="inline-block" href="#">
                  <button
                    class="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-pink-500 transition-all hover:bg-pink-500/10 active:bg-pink-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                  >
                    Download
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                      class="h-4 w-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      ></path>
                    </svg>
                  </button>
                </a>
              </div>
            </div>
            <link rel="stylesheet" href="https://riftvalley.org.et/gallery" />
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryDetail;
