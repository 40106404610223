import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { singleusers } from "../../States/Action/User-Action/UserAction";
import { useDispatch, useSelector } from "react-redux";

const SingleUser = () => {
  // dispatch
  const dispatch = useDispatch();
  // useSelectors
  const users = useSelector((state) => state.users);
  //  console.log("single user : ", users);

  return (
    <div className="relative px-4 pb-4">
      <div className="p-6 pt-0 ">
        <div class="bg-gray-200 font-sans h-full w-full flex flex-row justify-center items-center">
          <div class="card w-96 mx-auto bg-white  shadow-sm hover:shadow">
            <Avatar src="" sx={{ width: "80px", height: "80px", mx: "auto" }} />
            <div class="text-center mt-2 text-3xl font-medium">
              {users?.users?.user?.full_name || "Abie kebi"}
            </div>
            <div class="text-center mt-2 font-light text-sm">
              {users?.users.user?.email || "example@gmail.com"}
            </div>
            <div class="flex items-center justify-between px-6 text-center mt-2 font-light text-sm">
              <p>
                Phone Number :{" "}
                {users?.users?.user?.phoneNumber || "+25112345678"}
              </p>
              <p>Role : {users?.users?.user?.phoneNumber || "+25112345678"}</p>
            </div>
            <hr class="mt-8" />
            <div class="flex p-4">
              <div class="w-1/2 text-center">
                <span class="font-bold">3.7 k</span> create content
              </div>
              <div class="w-0 border border-gray-300"></div>
              <div class="w-1/2 text-center">
                <button
                  type="submit"
                  class="bg-gray-400 hover:bg-hoverButton text-white font-bold py-2 px-4 rounded"
                >
                  1.2 K publication
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleUser;
