import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import Loading from "../Loading/loading";
import { useDispatch, useSelector } from "react-redux";
import {
  viewContent,
  deleteContent,
  approveContent,
  singleContent,
} from "../../States/Action/Content-Action/ContentAction";
import NewsModel from "../../components/popup-model";
import NewsDetail from "./NewsDetail";
import ConfirmationDialog from "../windowsPopUp";

const NewsList = () => {
  const dispatch = useDispatch();
  const [news, setNews] = useState();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const contents = useSelector((state) => state.contents);
  useEffect(() => {
    dispatch(viewContent());
  }, []);

  // console.log("content list : ", contents);
  const Role = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  function parseJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const userRole = parseJWT(Role);

  const handleDelete = async (id) => {
    setDialogOpen(true);
    localStorage.setItem("id", JSON.stringify(id));
  };
  const handleConfirm = () => {
    setDialogOpen(false);
    const id = localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : null;
    dispatch(deleteContent(id));
    setTimeout(function () {
      dispatch(viewContent());
    }, 1000);
  };
  const handleApprove = async (data) => {
    const newData = {
      id: data?.id,
      status: "APPROVED",
    };
    if (window.confirm("Are you sure you want to approve this news item?")) {
      dispatch(approveContent(newData));
      setTimeout(function () {
        dispatch(viewContent());
      }, 2000);
    }
  };
  const handleCancleApprove = async (data) => {
    const newData = {
      id: data?.id,
      status: "PENDING",
    };
    if (window.confirm("Are you sure you want to disapprove this item?")) {
      dispatch(approveContent(newData));
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  };

  const handleContentDetail = (id) => {
    if (id !== null) {
      dispatch(singleContent(id));
      setNews(true);
    }
  };
  const handleNewsCancle = () => {
    setNews(false);
    dispatch(viewContent());
  };
  const isAdminOrModerator =
    userRole?.role === "admin" || userRole.role === "moderator";
  const isUser = userRole.role === "user";
  const isAdmin = userRole?.role === "admin";

  const columns = [
    {
      field: "media_file", //access nested data with dot notation
      headerName: "Image",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <img
            src={`https://backend.riftvalley.org.et/uploads/${params.value}`}
            width={60}
          />
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "content_type",
      headerName: "Type",
      minWidth: 120,
      flex: 0.4,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 250,
      flex: 1.5,
      minHeight: "Auto",
    },
    {
      field: "posted_by",
      headerName: "Posted By",
      minWidth: 120,
      flex: 0.8,
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      minWidth: 120,
      flex: 0.8,
    },
    // {
    //   field: "createdAt",
    //   headerName: "Created At",
    //   minWidth: 150,
    //   flex: 1,
    //   valueGetter: (params) => new Date(params.row.createdAt).toLocaleString(),
    // },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 0.8,
      renderHeader: (params) =>
        params.field === "status" ? "Status" : <FaCheck size={20} />,
      renderCell: (params) => (
        <div
          style={{ color: params.value === "APPROVED" ? "green" : "inherit" }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.1,
      minWidth: 250,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Button onClick={() => handleContentDetail(params.id)}>
            <AiOutlineEye size={20} />
          </Button>
          {isAdminOrModerator && (
            <Link to={`/update-news/${params.id}`}>
              <Button>
                <AiOutlineEdit size={20} />
              </Button>
            </Link>
          )}
          {isAdmin && (
            <>
              <Button onClick={() => handleDelete(params.id)}>
                <AiOutlineDelete size={20} />
              </Button>
            </>
          )}
        </div>
      ),
    },
    isUser == true
      ? ""
      : {
          field: "Approve",
          headerName: "Approve",
          flex: 0.8,
          minWidth: 150,

          renderCell: (params) => (
            <>
              {isAdminOrModerator && (
                <div>
                  {params.row.status === "APPROVED" && (
                    <Button
                      style={{
                        color: "#388b84",
                        backgroundColor: "#87CEEB",
                        padding: "4px",
                        border: "50px",
                      }}
                      onClick={() => handleCancleApprove(params.row)}
                    >
                      {params.row.status}
                    </Button>
                  )}
                  {params.row.status === "PENDING" && (
                    <Button
                      style={{
                        color: "#388b84",
                        backgroundColor: "#388b8433",
                        padding: "4px",
                        border: "50px",
                      }}
                      onClick={() => handleApprove(params.row)}
                    >
                      {params.row.status}
                    </Button>
                  )}
                </div>
              )}
            </>
          ),
        },
  ];
  const rows = contents?.contents?.contents?.map((contents) => ({
    id: contents?.id,
    title: contents?.title,
    description: contents?.description,
    content_type: contents?.content_type,
    status: contents?.status,
    media_file: contents?.media_file,
    posted_by: contents?.user?.full_name,
    approved_by: contents?.users?.full_name,
    createdAt: new Date(contents?.createdAt),
    updatedAt: new Date(contents?.updatedAt),
  }));

  return (
    <>
      <div className="w-full pt-1 mt-10 bg-white text-center">
        <h1 className="text-2xl mb-4">News</h1>
        <div style={{ overflowY: "auto" }}>
          {contents?.contents?.contents ? (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={8}
              disableSelectionOnClick
              autoHeight
            />
          ) : (
            <Loading />
          )}
        </div>
      </div>

      <NewsModel isVisible={news} onClose={() => handleNewsCancle()}>
        <NewsDetail />
      </NewsModel>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to delete this content ?"
      />
    </>
  );
};

export default NewsList;
