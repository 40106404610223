import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CreateUserPage from "./components/Users/CreateUser";
import DashboardPage from "./components/DashboardHero";
import UserListPage from "./components/Users/UserList";
import CreateContentPage from "./components/CreateContentsForm";
import GalleryListPage from "./components/Gallery/GalleryList";
import FeedBackListPage from "./components/Feedback/FeedbackList";
import NewsListPage from "./components/News/NewsList";
import OrganizationDetailPage from "./components/Organization/OrganizationData";
import LoginPage from "./components/Users/Login";
import VideoListPage from "./components/Video/Videos";
import PublicationListPage from "./components/Publication/PublicationList";
import MainLayout from "./components/Layout/MainLayout";
import NewsDetail from "./components/News/NewsDetail";
import ProtectedRoutes from "./components/protectedRoutes/ProtectedRoutes";
import NotFound from "./components/NotFound/NotFound";
import UpdateUser from "./components/Users/UpdateUser";
import UpdatePublication from "./components/Publication/UpdatePublication";
import UpdateNews from "./components/News/UpdateNews";
import UpdateGallery from "./components/Gallery/UpdateGallery";
import SingleUser from "./components/Users/UserDetail";
import UpdateOrganization from "./components/Organization/UpdateOrganization";
import OrganizationDetail from "./components/Organization/OrganizationDetail";
import CreateBoardmembers from "./components/Boardmembers/CreatBoardm";
import BoardmemberView from "./components/Boardmembers/BoardmemberView";
import UpdateBoardMembers from "./components/Boardmembers/UpdateBoardmember";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={"login"} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<MainLayout />}>
            <Route
              path="/dashboard"
              element={<DashboardPage />}
              requiredRole={["admin", "user", "moderator"]}
            />
            <Route
              path="create-user"
              element={<CreateUserPage />}
              requiredRole={["admin"]}
            />
            <Route
              path="create-content"
              element={<CreateContentPage />}
              requiredRole={["admin", "user", "moderator"]}
            />
            <Route
              path="update-userInfo/:id"
              element={<UpdateUser />}
              requiredRole={["admin", "user", "moderator"]}
            />
            <Route
              path="users"
              element={<UserListPage />}
              requiredRole={["admin", "moderator"]}
            />
            <Route
              path="create-boardmember"
              element={<CreateBoardmembers />}
              requiredRole={["admin"]}
            />
            <Route
              path="boardmembers"
              element={<BoardmemberView />}
              requiredRole={["admin", "moderator"]}
            />
            <Route
              path="update-BoardMembers/:id"
              element={<UpdateBoardMembers />}
              requiredRole={["admin", "moderator"]}
            />
            <Route
              path="news"
              element={<NewsListPage />}
              requiredRole={["admin", "moderator", "user"]}
            />
            <Route
              path="update-news/:id"
              element={<UpdateNews />}
              requiredRole={["admin", "moderator", "user"]}
            />
            <Route path="/news/:id" component={NewsDetail} />
            <Route
              path="/update-gallery/:id"
              element={<UpdateGallery />}
              requiredRole={["admin", "moderator", "user"]}
            />
            <Route
              path="/singleUser/:id"
              element={<SingleUser />}
              requiredRole={["admin", "moderator", "user"]}
            />
            <Route
              path="videos"
              element={<VideoListPage />}
              requiredRole={["admin", "moderator", "user"]}
            />
            <Route
              path="galleries"
              element={<GalleryListPage />}
              requiredRole={["admin", "moderator", "user"]}
            />
            <Route
              path="feedbacks"
              element={<FeedBackListPage />}
              requiredRole={["admin", "moderator"]}
            />
            <Route
              path="organizations"
              element={<OrganizationDetailPage />}
              requiredRole={["admin", "moderator"]}
            />
            <Route
              path="update-organization/:id"
              element={<UpdateOrganization />}
              requiredRole={["admin", "moderator"]}
            />
            <Route
              path="single-organization/:id"
              element={<OrganizationDetail />}
              requiredRole={["admin"]}
            />
            <Route
              path="publications"
              element={<PublicationListPage />}
              requiredRole={["admin", "moderator", "user"]}
            />
            <Route
              path="/update-publication/:id"
              element={<UpdatePublication />}
              requiredRole={["admin", "moderator", "user"]}
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
};

export default App;
