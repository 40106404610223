import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  viewUsers,
  deleteusers,
  singleusers,
} from "../../States/Action/User-Action/UserAction";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../Loading/loading";
import UserPopUp from "../popup-model";
import SingleUser from "./UserDetail";
import ConfirmationDialog from "../windowsPopUp";

const UserList = () => {
  //const [users, setUsers] = useState([]);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [singleUser, setUserDetail] = useState();
  const [isDialogOpen, setDialogOpen] = useState(false);

  // use effect
  useEffect(() => {
    dispatch(viewUsers());
  }, []);

  const handleDelete = async (id) => {
    setDialogOpen(true);
    localStorage.setItem("id", JSON.stringify(id));
  };
  const handleConfirm = () => {
    setDialogOpen(false);
    const id = localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : null;
    dispatch(deleteusers(id));
    setTimeout(function () {
      dispatch(viewUsers());
    }, 1000);
  };

  const handleUserDetail = (id) => {
    if (id !== null) {
      dispatch(singleusers(id));
      setUserDetail(true);
    }
  };
  const handleUsersCancle = () => {
    setUserDetail(false);
    dispatch(viewUsers());
  };
  const columns = [
    // {
    //   field: "id",
    //   headerName: "UserID",
    //   minWidth: 120,
    //   flex: 0.8,
    // },
    {
      field: "full_name",
      headerName: "Full Name",
      minWidth: 120,
      flex: 0.8,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      minWidth: 120,
      flex: 0.8,
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      type: "string",
      minWidth: 120,
      flex: 0.8,
    },
    {
      field: "role",
      headerName: "Role",
      type: "string",
      minWidth: 120,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 120,
      flex: 0.8,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   type: "string",
    //   minWidth: 120,
    //   flex: 0.8,
    // },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      minWidth: 250,
      sortable: false,
      renderCell: (params) => {
        const { id, full_name } = params?.row;
        const product_name = full_name?.replace(/\s+/g, "-");

        return (
          <div>
            <Button onClick={() => handleUserDetail(params.id)}>
              <AiOutlineEye size={20} />
            </Button>

            <Link to={`/update-userInfo/${id}`}>
              <Button>
                <AiOutlineEdit size={20} />
              </Button>
            </Link>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="w-full pt-1 mt-10 bg-white text-center">
        <h1 className="text-2xl  mb-4">Users</h1> {/* Title added */}
        {users?.users?.users ? (
          <DataGrid
            rows={users?.users?.users}
            columns={columns}
            pageSize={8}
            disableSelectionOnClick
            autoHeight
          />
        ) : (
          <Loading />
        )}
      </div>
      <UserPopUp isVisible={singleUser} onClose={() => handleUsersCancle()}>
        <SingleUser />
      </UserPopUp>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to delete this user?"
      />
    </>
  );
};

export default UserList;
