import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";

const SidebarItem = ({ to, icon: Icon, label, active }) => (
  
  <ListItem button component={Link} to={to} selected={active}>
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
    <ListItemText primary={label} />
  </ListItem>
);

export default SidebarItem;
