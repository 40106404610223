import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { server } from "../server";
import { useDispatch, useSelector } from "react-redux";
import {
  createGallery,
  viewGallery,
} from "../States/Action/Gallery-Action/GalleryAction";
import {
  createContent,
  viewContent,
} from "../States/Action/Content-Action/ContentAction";
import {
  createPublication,
  viewPublication,
} from "../States/Action/Publication-Action/PublicationAction";
import {
  createOrganization,
  viewOrganization,
} from "../States/Action/Organization-Action/OrganizationAction";
import { useNavigate } from "react-router-dom";
import {
  viewUsers,
  deleteusers,
  singleusers,
} from "../States/Action/User-Action/UserAction";

const CreateContentForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [picture, setPicture] = useState(null);
  const [getImg, setImg] = useState(null);

  const [formType, setFormType] = useState("");
  const [contentType, setContentType] = useState("");
  const [gallery, setGallery] = useState({
    title: "",
    description: "",
    location: "",
    gallery: null,
  });
  const [content, setContent] = useState({
    title: "",
    description: "",
    media_file: null,
    video: null,
    content_type: "",
    location: "",
  });
  const [publication, setPublication] = useState({
    title: "",
    description: "",
    publication_type: "",
    publication: null,
  });
  const [organization, setOrganization] = useState({
    about_company: "",
    description_photo: null,
    mobile_number: "",
    organization_email: "",
    work_phone: "",
    company_moto: "",
    company_mission: "",
    company_vision: "",
    coordination_offices: 0,
    fund_mobilized: 0,
    beneficiaries_reached: 0,
    bank_name: "",
    bank_branch_name: "",
    bank_account_number: "",
    bank_swift_code: "",
    facebook_account: "",
    telegram_account: "",
    twitter_account: "",
    tiktok_account: "",
    instagram_account: "",
    youtube_account: "",
    pobox: "",
    fax: "",
  });
  const [partners, setPartners] = useState([
    { partner_name: "", partner_logo: null },
  ]);
  const [values, setValues] = useState([{ value_name: "", value_icon: null }]);
  const [programAreas, setProgramAreas] = useState([
    { program_area_name: "", program_area_icon: null },
  ]);
  const [boardMembers, setBoardMembers] = useState([
    { board_member_name: "", board_member_photo: null },
  ]);
  const [filePreviews, setFilePreviews] = useState({
    partners: [],
    values: [],
    programAreas: [],
    boardMembers: [],
    gallery: null,
    content: null,
    publication: null,
  });

  const handleFormTypeChange = (e) => {
    setFormType(e.target.value);
  };

  const handleInputChange = (e, index, type) => {
    e.persist();
    const { name, value } = e.target;
    switch (type) {
      case "partners":
        const newPartners = [...partners];
        newPartners[index][name] = value;
        setPartners(newPartners);
        break;
      case "organization":
        setOrganization({ ...organization, [name]: value });
        break;
      case "values":
        const newValues = [...values];
        newValues[index][name] = value;
        setValues(newValues);
        break;
      case "programAreas":
        const newProgramAreas = [...programAreas];
        newProgramAreas[index][name] = value;
        setProgramAreas(newProgramAreas);
        break;
      case "boardMembers":
        const newBoardMembers = [...boardMembers];
        newBoardMembers[index][name] = value;
        setBoardMembers(newBoardMembers);
        break;
      case "gallery":
        setGallery({ ...gallery, [name]: value });
        break;
      case "publication":
        setPublication({ ...publication, [name]: value });
        break;
      case "content":
        setContent({ ...content, [name]: value });
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleFileChange = (e, index, type) => {
    const files = e.target.files;
    setPicture(URL.createObjectURL(e.target.files[0]));
    setImg(e.target.files[0]);
    const updateStateAndPreview = (
      state,
      previewState,
      fieldName,
      file,
      idx
    ) => {
      const newState = [...state];
      newState[idx][fieldName] = file;
      const url = URL.createObjectURL(file);
      const newPreviews = [...filePreviews[previewState]];
      newPreviews[idx] = { file, url };
      setFilePreviews((prev) => ({ ...prev, [previewState]: newPreviews }));
      return newState;
    };

    switch (type) {
      case "partners":
        setPartners(
          updateStateAndPreview(
            partners,
            "partners",
            "partner_logo",
            files[0],
            index
          )
        );
        break;
      case "values":
        if (files.length === 1) {
          setValues(
            updateStateAndPreview(
              values,
              "values",
              "value_icon",
              files[0],
              index
            )
          );
        } else {
          const updatedValues = [...values];
          Array.from(files).forEach((file, i) => {
            const newIndex = index + i;
            if (updatedValues[newIndex]) {
              updatedValues[newIndex].value_icon = file;
            } else {
              updatedValues.push({ value_icon: file });
            }
            const url = URL.createObjectURL(file);
            const newPreviews = [...filePreviews.values];
            newPreviews[newIndex] = { file, url };
            setFilePreviews((prev) => ({ ...prev, values: newPreviews }));
          });
          setValues(updatedValues);
        }
        break;
      case "programAreas":
        setProgramAreas(
          updateStateAndPreview(
            programAreas,
            "programAreas",
            "program_area_icon",
            files[0],
            index
          )
        );
        break;
      case "boardMembers":
        setBoardMembers(
          updateStateAndPreview(
            boardMembers,
            "boardMembers",
            "board_member_photo",
            files[0],
            index
          )
        );
        break;
      case "gallery":
        setGallery({ ...gallery, gallery: files[0] });

        setFilePreviews((prev) => ({
          ...prev,
          gallery: { file: files[0], url: URL.createObjectURL(files[0]) },
        }));
        break;
      case "publication":
        setPublication({ ...publication, publication: files[0] });
        setFilePreviews((prev) => ({
          ...prev,
          publication: { file: files[0], url: URL.createObjectURL(files[0]) },
        }));
        break;
      case "content":
        const newContentState = { ...content };
        if (files[0].type.startsWith("image/")) {
          newContentState.media_file = files[0];
        } else if (files[0].type.startsWith("vidio/")) {
          newContentState.media_file = files[0];
        }
        setContent(newContentState);
        setFilePreviews((prev) => ({
          ...prev,
          content: { file: files[0], url: URL.createObjectURL(files[0]) },
        }));
        break;
      case "organization":
        setOrganization(
          updateStateAndPreview(
            [organization],
            "organization",
            "description_photo",
            files[0],
            0
          )
        );
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleRemoveField = (index, type) => {
    switch (type) {
      case "partners":
        setPartners(partners.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          partners: prev.partners.filter((_, i) => i !== index),
        }));
        break;
      case "values":
        setValues(values.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          values: prev.values.filter((_, i) => i !== index),
        }));
        break;
      case "programAreas":
        setProgramAreas(programAreas.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          programAreas: prev.programAreas.filter((_, i) => i !== index),
        }));
        break;
      case "boardMembers":
        setBoardMembers(boardMembers.filter((_, i) => i !== index));
        setFilePreviews((prev) => ({
          ...prev,
          boardMembers: prev.boardMembers.filter((_, i) => i !== index),
        }));
        break;
      case "gallery":
        setGallery({ title: "", description: "", gallery: null });
        setFilePreviews((prev) => ({ ...prev, gallery: null }));
        break;
      case "publication":
        setPublication({
          title: "",
          description: "",
          publication_type: "",
          publication: null,
        });
        setFilePreviews((prev) => ({ ...prev, publication: null }));
        break;
      case "content":
        setContent({
          title: "",
          description: "",
          media_file: null,
          content_type: "",
          video: null,
          location: "",
        });
        setFilePreviews((prev) => ({ ...prev, content: null }));
        break;
      case "organization":
        setOrganization({
          about_company: "",
          description_photo: null,
          mobile_number: "",
          organization_email: "",
          work_phone: "",
          company_moto: "",
          company_mission: "",
          company_vision: "",
          coordination_offices: 0,
          fund_mobilized: 0,
          beneficiaries_reached: 0,
          bank_name: "",
          bank_branch_name: "",
          bank_account_number: "",
          bank_swift_code: "",
          facebook_account: "",
          telegram_account: "",
          twitter_account: "",
          tiktok_account: "",
          instagram_account: "",
          youtube_account: "",
          pobox: "",
          fax: "",
        });
        setFilePreviews((prev) => ({ ...prev, organization: null }));
        break;
      default:
        console.error("Unexpected type:", type);
        break;
    }
  };

  const handleAddField = (type) => {
    if (type === "partners") {
      setPartners([...partners, { partner_name: "", partner_logo: null }]);
    } else if (type === "values") {
      setValues([...values, { value_name: "", value_icon: null }]);
    } else if (type === "programAreas") {
      setProgramAreas([
        ...programAreas,
        { program_area_name: "", program_area_icon: null },
      ]);
    } else if (type === "boardMembers") {
      setBoardMembers([
        ...boardMembers,
        { board_member_name: "", board_member_photo: null },
      ]);
    }
  };
  const Role = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  function parseJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  // console.log("user detial : ", users?.users?.user?.full_name);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formType === "gallery") {
      e.gallery = getImg;
      e.title = gallery.title;
      e.description = gallery.description;
      e.location = gallery.location;
      const formData = new FormData();
      Object?.entries(e).map((entry) => {
        const [key, value] = entry;
        formData.append(key, value);
      });
      dispatch(createGallery(formData));
      setTimeout(function () {
        navigate("/galleries");
      }, 3000);
    } else if (formType === "content") {
      e.media_file = getImg;
      e.content_type = content.content_type;
      e.title = content.title;
      e.description = content.description;
      e.location = content.location;
      const formData = new FormData();
      Object?.entries(e).map((entry) => {
        const [key, value] = entry;
        formData.append(key, value);
      });
      dispatch(createContent(formData));
      setTimeout(function () {
        navigate("/news");
      }, 3000);
    } else if (formType === "publication") {
      e.publication = getImg;
      e.publication_type = publication.publication_type;
      e.title = publication.title;
      e.description = publication.description;
      const formData = new FormData();
      Object?.entries(e).map((entry) => {
        const [key, value] = entry;
        formData.append(key, value);
      });
      dispatch(createPublication(formData));
      setTimeout(function () {
        navigate("/publications");
      }, 3000);
    } else if (formType === "organization") {
      const formDataToSend = new FormData();
      Object.keys(organization).forEach((key) => {
        if (organization[key]) formDataToSend.append(key, organization[key]);
      });

      formDataToSend.append("boardMembers", JSON.stringify(boardMembers));
      boardMembers.forEach((boardMember, index) => {
        if (boardMember.board_member_photo) {
          formDataToSend.append(
            `board_member_photo`,
            boardMember.board_member_photo,
            boardMember.board_member_photo.name
          );
        }
      });

      formDataToSend.append("partners", JSON.stringify(partners));
      partners.forEach((partner, index) => {
        if (partner.partner_logo) {
          formDataToSend.append(
            `partner_logo`,
            partner.partner_logo,
            partner.partner_logo.name
          );
        }
      });

      formDataToSend.append("values", JSON.stringify(values));
      values.forEach((value, index) => {
        if (value.value_icon) {
          formDataToSend.append(
            `value_icon`,
            value.value_icon,
            value.value_icon.name
          );
        }
      });

      formDataToSend.append("programAreas", JSON.stringify(programAreas));
      programAreas.forEach((programArea, index) => {
        if (programArea.program_area_icon) {
          formDataToSend.append(
            `program_area_icon`,
            programArea.program_area_icon,
            programArea.program_area_icon.name
          );
        }
      });

      dispatch(createOrganization(formDataToSend));
      setTimeout(function () {
        navigate("/organizations");
      }, 3000);

      //  dispatch(
      //  createOrganization({
      // about_company: organization.about_company,
      //  description_photo: organization.description_photo,
      //    mobile_number: organization.mobile_number,
      //   organization_email: organization.organization_email,
      //  work_phone: organization.work_phone,
      //   company_moto: organization.company_moto,
      //    company_mission: organization.company_mission,
      //    company_vision: organization.company_vision,
      //  coordination_offices: organization.coordination_offices,
      //  fund_mobilized: organization.fund_mobilized,
      //  beneficiaries_reached: organization.beneficiaries_reached,
      //   bank_name: organization.bank_name,
      //  bank_branch_name: organization.bank_branch_name,
      //  bank_account_number: organization.bank_account_number,
      //  bank_swift_code: organization.bank_swift_code,
      // facebook_account: organization.facebook_account,
      // telegram_account: organization.telegram_account,
      // twitter_account: organization.twitter_account,
      // tiktok_account: organization.tiktok_account,
      // instagram_account: organization.instagram_account,
      // youtube_account: organization.youtube_account,
      //   pobox: organization.pobox,
      //  fax: organization.fax,
      // })
      //  );
    }
  };

  const renderPreview = (preview) => {
    const { file, url } = preview;
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={url}
          alt={file.name}
          className="w-16 h-16 object-cover rounded-md"
        />
      );
    }
    if (file.type.startsWith("video/")) {
      return <video src={url} controls className="w-16 h-16 rounded-md" />;
    }
    if (file.type === "application/pdf") {
      return (
        <object data={url} type="application/pdf" width="100%" height="100px">
          <a href={url} target="_blank" rel="noopener noreferrer">
            View PDF
          </a>
        </object>
      );
    }
    return <p>Unsupported file type</p>;
  };

  const handleContentTypeChange = (e) => {
    const selectedContentType = e.target.value;
    setContentType(selectedContentType);

    // Automatically set the content type based on the selected radio button
    if (selectedContentType === "video") {
      setContent((prevContent) => ({
        ...prevContent,
        content_type: "video",
      }));
    } else if (selectedContentType === "news") {
      setContent((prevContent) => ({
        ...prevContent,
        content_type: "news",
      }));
    } else if (selectedContentType === "reports") {
      setPublication((prevContent) => ({
        ...prevContent,
        publication_type: "reports",
      }));
    } else if (selectedContentType === "researches") {
      setPublication((prevContent) => ({
        ...prevContent,
        publication_type: "researches",
      }));
    }
  };

  return (
    <form className="mt-16 ml-8 mr-8" onSubmit={handleFormSubmit}>
      <div className="flex justify-center mb-8">
        <label htmlFor="formType" className="text-lg" color="primary">
          Select Content Type:
        </label>
        <select
          className="text-xl"
          id="formType"
          value={formType}
          onChange={handleFormTypeChange}
        >
          <option value="">--Select--</option>
          <option value="content">Content</option>
          <option value="gallery">Gallery</option>
          <option value="organization">Organization</option>
          <option value="publication">Publication</option>
        </select>
      </div>

      {formType === "content" && (
        <div className="space-y-4">
          <div className="flex space-x-4">
            <RadioGroup
              aria-label="content-type"
              name="content-type"
              value={contentType}
              onChange={handleContentTypeChange}
            >
              <FormControlLabel
                value="video"
                control={<Radio color="primary" />}
                label="Video"
              />
              <FormControlLabel
                value="news"
                control={<Radio color="primary" />}
                label="News"
              />
            </RadioGroup>
          </div>
          <TextField
            label="Title"
            variant="outlined"
            name="title"
            value={content.title}
            onChange={(e) => handleInputChange(e, 0, "content")}
            fullWidth
            disabled={!contentType}
            className="my-4"
          />
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            value={content.description}
            onChange={(e) => handleInputChange(e, 0, "content")}
            multiline
            rows={4}
            fullWidth
            disabled={!contentType}
            className="my-4"
          />
          <TextField
            label="Location"
            variant="outlined"
            name="location"
            value={content.location}
            onChange={(e) => handleInputChange(e, 0, "content")}
            fullWidth
            disabled={!contentType}
            className="my-4"
          />
          <div className="space-x-4">
            <input
              type="file"
              accept="*"
              name="media_file"
              onChange={(e) => handleFileChange(e, 0, "content")}
              className="hidden"
              id="media-file-upload"
            />
            <label htmlFor="media-file-upload">
              <Button
                variant="contained"
                component="span"
                startIcon={<AddIcon />}
                color="primary"
              >
                Upload Media File
              </Button>
            </label>
          </div>
          {filePreviews.content && (
            <div>Preview: {renderPreview(filePreviews.content)}</div>
          )}
        </div>
      )}

      {formType === "publication" && (
        <div className="space-y-4">
          <div className="flex space-x-4">
            <RadioGroup
              aria-label="publication-type"
              name="publication-type"
              value={contentType}
              onChange={handleContentTypeChange}
            >
              <FormControlLabel
                value="reports"
                control={<Radio color="primary" />}
                label="Reports"
              />
              <FormControlLabel
                value="researches"
                control={<Radio color="primary" />}
                label="Researches"
              />
            </RadioGroup>
          </div>
          <TextField
            label="Title"
            variant="outlined"
            name="title"
            value={publication.title}
            onChange={(e) => handleInputChange(e, 0, "publication")}
            fullWidth
            disabled={!contentType}
            className="my-4"
          />
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            value={publication.description}
            onChange={(e) => handleInputChange(e, 0, "publication")}
            multiline
            rows={4}
            fullWidth
            disabled={!contentType}
            className="my-4"
          />
          <div className="space-x-4">
            <input
              type="file"
              accept="*"
              filename="publication"
              onChange={(e) => handleFileChange(e, 0, "publication")}
              className="hidden"
              id="publication-file-upload"
            />
            <label htmlFor="publication-file-upload">
              <Button
                variant="contained"
                component="span"
                startIcon={<AddIcon />}
                color="primary"
              >
                Upload Publication File
              </Button>
            </label>
          </div>
          {filePreviews.publication && (
            <div>Preview: {renderPreview(filePreviews.publication)}</div>
          )}
        </div>
      )}

      {formType === "gallery" && (
        <div className="space-y-4">
          <TextField
            label="Title"
            variant="outlined"
            name="title"
            value={gallery.title}
            onChange={(e) => handleInputChange(e, 0, "gallery")}
            fullWidth
            className="my-4"
          />
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            value={gallery.description}
            onChange={(e) => handleInputChange(e, 0, "gallery")}
            multiline
            rows={4}
            fullWidth
            className="my-4"
          />

          <TextField
            label="Location"
            variant="outlined"
            name="location"
            value={gallery.location}
            onChange={(e) => handleInputChange(e, 0, "gallery")}
            fullWidth
            className="my-4"
          />
          <div className="space-x-4">
            <input
              type="file"
              accept="image/*"
              name="gallery"
              onChange={(e) => handleFileChange(e, 0, "gallery")}
              multiple
              className="hidden"
              id="gallery-upload"
            />
            <label htmlFor="gallery-upload">
              <Button
                variant="contained"
                component="span"
                startIcon={<AddIcon />}
                color="primary"
              >
                Upload Gallery Images
              </Button>
            </label>
          </div>
          {filePreviews.gallery && (
            <div>Preview: {renderPreview(filePreviews.gallery)}</div>
          )}
        </div>
      )}

      {formType === "organization" && (
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1 space-y-4">
            <TextField
              label="About Company"
              variant="outlined"
              name="about_company"
              value={organization.about_company}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
              multiline
              rows={4}
            />
            <div className="flex items-center space-x-2">
              <input
                type="file"
                name="description_photo"
                onChange={(e) => handleFileChange(e, null, "organization")}
                id="description_photo"
                className="hidden"
              />
              <label htmlFor="description_photo">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                  color="primary"
                >
                  Upload Description Photo
                </Button>
              </label>
            </div>
            <div>
              {filePreviews.organization && (
                <div>Preview: {renderPreview(filePreviews.organization)}</div>
              )}
            </div>

            {/* {renderPreview(filePreviews.organization)} */}
            <TextField
              label="Mobile Number"
              variant="outlined"
              name="mobile_number"
              value={organization.mobile_number}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Organization Email"
              variant="outlined"
              name="organization_email"
              value={organization.organization_email}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Work Phone"
              variant="outlined"
              name="work_phone"
              value={organization.work_phone}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Company Moto"
              variant="outlined"
              name="company_moto"
              value={organization.company_moto}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="PO Box"
              variant="outlined"
              name="pobox"
              value={organization.pobox}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Fax"
              variant="outlined"
              name="fax"
              value={organization.fax}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
          </div>

          <div className="col-span-1 space-y-4">
            <TextField
              label="Company Mission"
              variant="outlined"
              name="company_mission"
              value={organization.company_mission}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
              multiline
              rows={4}
            />
            <TextField
              label="Company Vision"
              variant="outlined"
              name="company_vision"
              value={organization.company_vision}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
              multiline
              rows={4}
            />
            <TextField
              label="Coordination Offices"
              variant="outlined"
              name="coordination_offices"
              value={organization.coordination_offices}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
              type="number"
            />
            <TextField
              label="Fund Mobilized"
              variant="outlined"
              name="fund_mobilized"
              value={organization.fund_mobilized}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
              type="number"
            />
            <TextField
              label="Beneficiaries Reached"
              variant="outlined"
              name="beneficiaries_reached"
              value={organization.beneficiaries_reached}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
              type="number"
            />
            <TextField
              label="YouTube Account"
              variant="outlined"
              name="youtube_account"
              value={organization.youtube_account}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Instagram Account"
              variant="outlined"
              name="instagram_account"
              value={organization.instagram_account}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
          </div>

          <div className="col-span-1 space-y-4">
            <TextField
              label="Bank Name"
              variant="outlined"
              name="bank_name"
              value={organization.bank_name}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Bank Branch Name"
              variant="outlined"
              name="bank_branch_name"
              value={organization.bank_branch_name}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Bank Account Number"
              variant="outlined"
              name="bank_account_number"
              value={organization.bank_account_number}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Bank SWIFT Code"
              variant="outlined"
              name="bank_swift_code"
              value={organization.bank_swift_code}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Facebook Account"
              variant="outlined"
              name="facebook_account"
              value={organization.facebook_account}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Telegram Account"
              variant="outlined"
              name="telegram_account"
              value={organization.telegram_account}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="Twitter Account"
              variant="outlined"
              name="twitter_account"
              value={organization.twitter_account}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
            <TextField
              label="TikTok Account"
              variant="outlined"
              name="tiktok_account"
              value={organization.tiktok_account}
              onChange={(e) => handleInputChange(e, 0, "organization")}
              fullWidth
            />
          </div>
          <div className="col-span-1 space-y-4">
            {/* Partners Section */}
            <div className="col-span-3 space-y-4">
              <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
                Add Partners
              </h3>
              {partners.map((partner, index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 gap-4 items-center"
                >
                  <TextField
                    label="Partner Name"
                    variant="outlined"
                    name="partner_name"
                    value={partner.partner_name}
                    onChange={(e) => handleInputChange(e, index, "partners")}
                    fullWidth
                  />
                  <input
                    type="file"
                    name="partner_logo"
                    onChange={(e) => handleFileChange(e, index, "partners")}
                    id={`partner_logo_${index}`}
                    className="hidden"
                  />
                  <label htmlFor={`partner_logo_${index}`}>
                    <Button
                      variant="contained"
                      component="span"
                      startIcon={<PhotoCamera />}
                      color="primary"
                    >
                      Partner Logo
                    </Button>
                  </label>
                  {filePreviews.partners[index] && (
                    <div>
                      Preview: {renderPreview(filePreviews.partners[index])}
                    </div>
                  )}
                  <IconButton
                    onClick={() => handleRemoveField(index, "partners")}
                  >
                    <RemoveIcon />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => handleAddField("partners")}
              >
                Add Partner
              </Button>
            </div>

            {/* Board Members Section */}
            <div className="col-span-3 space-y-4">
              <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
                Add Board Members
              </h3>
              {boardMembers.map((boardMember, index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 gap-4 items-center"
                >
                  <TextField
                    label="Board Member Name"
                    variant="outlined"
                    name="board_member_name"
                    value={boardMember.board_member_name}
                    onChange={(e) =>
                      handleInputChange(e, index, "boardMembers")
                    }
                    fullWidth
                  />
                  <input
                    type="file"
                    name="board_member_photo"
                    onChange={(e) => handleFileChange(e, index, "boardMembers")}
                    id={`board_member_photo_${index}`}
                    className="hidden"
                  />
                  <label htmlFor={`board_member_photo_${index}`}>
                    <Button
                      variant="contained"
                      component="span"
                      startIcon={<PhotoCamera />}
                      color="primary"
                    >
                      Member Photo
                    </Button>
                  </label>
                  {filePreviews.boardMembers[index] && (
                    <div>
                      Preview: {renderPreview(filePreviews.boardMembers[index])}
                    </div>
                  )}
                  {/* {renderPreview(filePreviews.boardMembers[index], index, 'boardMembers')} */}
                  <IconButton
                    onClick={() => handleRemoveField(index, "boardMembers")}
                  >
                    <RemoveIcon />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => handleAddField("boardMembers")}
              >
                Add Board Member
              </Button>
            </div>
          </div>

          <div className="col-span-1 space-y-4">
            {/* Programmatic Areas Section */}
            <div className="col-span-3 space-y-4">
              <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
                Add Program Areas
              </h3>
              {programAreas.map((programArea, index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 gap-4 items-center"
                >
                  <TextField
                    label="Programmatic Area Name"
                    variant="outlined"
                    name="program_area_name"
                    value={programArea.program_area_name}
                    onChange={(e) =>
                      handleInputChange(e, index, "programAreas")
                    }
                    fullWidth
                  />
                  <input
                    type="file"
                    name="program_area_icon"
                    onChange={(e) => handleFileChange(e, index, "programAreas")}
                    id={`program_area_icon_${index}`}
                    className="hidden"
                  />
                  <label htmlFor={`program_area_icon_${index}`}>
                    <Button
                      variant="contained"
                      component="span"
                      startIcon={<PhotoCamera />}
                      color="primary"
                    >
                      Program Area Icon
                    </Button>
                  </label>
                  {filePreviews.programAreas[index] && (
                    <div>
                      Preview: {renderPreview(filePreviews.partners[index])}
                    </div>
                  )}
                  {/* {renderPreview(filePreviews.programAreas[index], index, 'programAreas')} */}
                  <IconButton
                    onClick={() => handleRemoveField(index, "programAreas")}
                  >
                    <RemoveIcon />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => handleAddField("programAreas")}
              >
                Add Program Area
              </Button>
            </div>

            {/* Values Section */}
            <div className="col-span-3 space-y-4">
              <h3 className="text-lg border-b border-b-gray-300 mb-2 text-center">
                Add Values
              </h3>
              {values.map((value, index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 gap-4 items-center"
                >
                  <TextField
                    label="Value Name"
                    variant="outlined"
                    name="value_name"
                    value={value.value_name}
                    onChange={(e) => handleInputChange(e, index, "values")}
                    fullWidth
                  />
                  <input
                    type="file"
                    name="value_icon"
                    onChange={(e) => handleFileChange(e, index, "values")}
                    id={`value_icon_${index}`}
                    className="hidden"
                  />
                  <label htmlFor={`value_icon_${index}`}>
                    <Button
                      variant="contained"
                      component="span"
                      startIcon={<PhotoCamera />}
                      color="primary"
                    >
                      Add Value Icon
                    </Button>
                  </label>
                  {filePreviews.values[index] && (
                    <div>
                      Preview: {renderPreview(filePreviews.partners[index])}
                    </div>
                  )}
                  {/* {renderPreview(filePreviews.values[index], index, 'values')} */}
                  <IconButton
                    onClick={() => handleRemoveField(index, "values")}
                  >
                    <RemoveIcon />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => handleAddField("values")}
              >
                Add Value
              </Button>
            </div>
          </div>
        </div>
      )}
      {formType && (
        <div className="my-4 item-center w-full text-center">
          <button
            class="group relative inline-block overflow-hidden border border-primary px-8 py-3 focus:outline-none focus:ring"
            type="submit"
          >
            <span class="absolute inset-y-0 left-0 w-[0px] bg-[#87CEEB] transition-all group-hover:w-1/2 group-active:primary"></span>
            <span class="absolute inset-y-0 right-0 w-[0px] bg-[#87CEEB] transition-all group-hover:w-1/2 group-active:bg-primary"></span>

            <span class="relative text-lg font-medium text-primary transition-colors group-hover:text-white uppercase font-serif">
              submit
            </span>
          </button>
        </div>
      )}
    </form>
  );
};

export default CreateContentForm;
