// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from 'react-toastify';

//List all Video
export const viewVideo = createAsyncThunk(
  "videos",
  async (thunkAPI) => {
    try {
      let response = await axios.get(`${server}/videoss`);
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
        toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//Add new Video
export const createVideo = createAsyncThunk(
  "videos",
  async (userData, thunkAPI) => {
    try {
      let response = await axios.post(`${server}/videoss`, userData);
      if (response.status === 201) {
        toast.success("Form submitted successfully!");
        return response.data.videos;
    } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
        toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// update Video
export const updateVideo = createAsyncThunk(
  "videos",
  async (videosData, thunkAPI) => {
    try {
      // response
      let response = await axios.put(
        `${server}/videoss/${videosData?.videosId}`,
        videosData
      );
      // condition
      if (response.status === 200) {
        toast.success("videos data successfuly updated !");
        return response.data.videos;
    } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
        toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Search Video
export const deleteVideo = createAsyncThunk(
  "videos",
  async (id, thunkAPI) => {
    try {
      let response = await axios.delete(`${server}/videoss/${id}`, id);
      if (response.status === 200) {
        toast.success("delete successfull !");
        return response.data.videos;
    } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
        toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const singleVideo = createAsyncThunk(
  "videos",
  async (id, thunkAPI) => {
    try {
      let response = await axios.get(`${server}/videoss/${id}`, id);
      if (response.status === 201) {
        return response.data.videos;
    } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
        toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
