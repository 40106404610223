import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineEye } from "react-icons/ai";
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import { MdBorderClear } from "react-icons/md";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { MdLibraryBooks } from "react-icons/md";
import { MdPhotoLibrary } from "react-icons/md";
import { MdFeedback } from "react-icons/md";
import { MdVideoLibrary } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { viewGallery } from "../States/Action/Gallery-Action/GalleryAction";
import { viewContent } from "../States/Action/Content-Action/ContentAction";
import { viewContactUs } from "../States/Action/Contactus-Action/ContactusAction";
import Loading from "./Loading/loading";
import { Avatar, Box, Grid, Paper, Typography, Divider } from "@mui/material";
import { FaEllipsisH } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { viewOrganization } from "../States/Action/Organization-Action/OrganizationAction";
import { backend_url } from "../server";

const DashboardHero = () => {
  const dispatch = useDispatch();
  //count all gallery
  // use effect
  useEffect(() => {
    dispatch(viewGallery());
  }, []);
  useEffect(() => {
    dispatch(viewOrganization());
  }, []);
  const gallery = useSelector((state) => state.gallery);
  //count all feedback
  const contactUs = useSelector((state) => state.contactUs);
  const organizations = useSelector((state) => state.organizations);
  useEffect(() => {
    dispatch(viewContactUs());
  }, []);

  //all contents
  const contents = useSelector((state) => state.contents);
  useEffect(() => {
    dispatch(viewContent());
  }, []);

  const [data, setData] = useState(contents || "");
  useEffect(() => {
    const result = contents?.contents?.contents?.filter((curData) => {
      return curData.content_type === "video";
    });
    setData(result);
  }, [contents]);

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   minWidth: 100,
    //   flex: 0.7,
    // },
    {
      field: "media_file", //access nested data with dot notation
      headerName: "Media file",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <img
            src={`https://backend.riftvalley.org.et/uploads/${params.value}`}
            width={60}
          />
        </div>
      ),
    },
    {
      field: "title",
      headerName: "News Title",
      minWidth: 150,
      flex: 0.7,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 180,
      flex: 0.7,
    },
    {
      field: "posted_by",
      headerName: "PostedBy",
      type: "string",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "approved_by",
      headerName: "ApprovedBy",
      type: "string",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 0.8,
      renderHeader: (params) =>
        params.field === "status" ? "Status" : <FaCheck size={20} />,
      renderCell: (params) => (
        <div
          style={{ color: params.value === "APPROVED" ? "green" : "inherit" }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  const rows = contents?.contents?.contents?.map((contents) => ({
    id: contents?.id,
    title: contents?.title,
    description: contents?.description,
    content_type: contents?.content_type,
    status: contents?.status,
    media_file: contents?.media_file,
    posted_by: contents?.user?.full_name,
    approved_by: contents?.users?.full_name,
    createdAt: new Date(contents?.createdAt),
    updatedAt: new Date(contents?.updatedAt),
  }));
  return (
    <div className="w-full flex flex-wrap justify-between mb-4">
      <div className="w-full mb-4 md:w-[20%] min-h-[20vh] bg-gray-50 shadow rounded px-2 py-5">
        <div className="flex items-center">
          <MdPhotoLibrary size={30} className="mr-2" fill="#A9aa59" />
          <h3
            className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
          >
            All Gallery
          </h3>
        </div>
        <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">
          {gallery?.gallery?.galleries?.length}
        </h5>
        <Link to="/galleries">
          <h5 className="pt-4 pl-2 text-[#077f9c]">View Galleries</h5>
        </Link>
      </div>

      <div className="w-full mb-4 md:w-[20%] min-h-[20vh] bg-gray-50 shadow rounded px-2 py-5">
        <div className="flex items-center">
          <MdLibraryBooks size={30} className="mr-2" fill="#A9aa59" />
          <h3
            className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
          >
            All News
          </h3>
        </div>
        <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">
          {contents?.contents?.contents?.length}
        </h5>
        <Link to="/news">
          <h5 className="pt-4 pl-2 text-[#077f9c]">View News</h5>
        </Link>
      </div>

      <div className="w-full mb-4 md:w-[20%] min-h-[20vh] bg-gray-50 shadow rounded px-2 py-5">
        <div className="flex items-center">
          <MdVideoLibrary size={30} className="mr-2" fill="#A9aa59" />
          <h3
            className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
          >
            All Videos
          </h3>
        </div>
        <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">
          {data?.length}
        </h5>
        <Link to="/videos">
          <h5 className="pt-4 pl-2 text-[#077f9c]">View Video</h5>
        </Link>
      </div>
      <div className="w-full mb-4 md:w-[20%] min-h-[20vh] bg-gray-50 shadow rounded px-2 py-5">
        <div className="flex items-center">
          <MdFeedback size={30} className="mr-2" fill="#A9aa59" />
          <h3
            className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
          >
            All Feedbacks
          </h3>
        </div>
        <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">
          {contactUs?.contactUs?.contacts?.length}
        </h5>
        <Link to="/feedbacks">
          <h5 className="pt-4 pl-2 text-[#077f9c]">View News</h5>
        </Link>
      </div>

      <div className="w-full bg-gray-50 rounded">
        <h3 className="text-[22px] ml-8 font-semibold font-Poppins pb-2">
          Latest News
        </h3>
        {contents?.contents?.contents ? (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default DashboardHero;
