// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

//List all Organization
export const viewOrganization = createAsyncThunk(
  "organizations",
  async (thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(
        `${server}/organizations/get-all-organization`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//Add new Organization
export const createOrganization = createAsyncThunk(
  "organizations",
  async (orgData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.post(
        `${server}/organizations/create`,
        orgData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success("Organization Created Successfully!");
        return response.data.organizations;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//Approve current Organization
export const approveOrganization = createAsyncThunk(
  "organizations",
  async (orgData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.put(
        `${server}/organizations/${orgData.id}`,
        orgData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success("Organization Data Approved !");
        return response.data.organizations;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// update Organization
export const updateOrganization = createAsyncThunk(
  "organizations",
  async (organizationsData, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      // response
      let response = await axios.put(
        `${server}/organizations/${organizationsData?.id}`,
        organizationsData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // condition
      if (response.status === 200) {
        toast.success("organizations data successfuly updated !");
        return response.data.organizations;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Search Organization
export const deleteOrganization = createAsyncThunk(
  "organizations",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.delete(`${server}/organizations/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        toast.success("single organization deleted !");
        return response.data.organizations;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const singleOrganization = createAsyncThunk(
  "organizations",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(`${server}/organizations/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
