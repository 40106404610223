import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  singleGallery,
  updateGallery,
} from "../../States/Action/Gallery-Action/GalleryAction";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

const UpdateGallery = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const gallery = useSelector((state) => state.gallery);

  const {
    handleSubmit,
    register,
    reset,
    //formState: { errors },
  } = useForm();

  useEffect(() => {
    onReset();
  }, []);

  const onReset = async () => {
    const result = await dispatch(singleGallery(id));
    reset(result.payload);
  };

  const [image, setImage] = useState(null);

  const [gallerys, setGallery] = useState({
    title: "",
    description: "",
    location: "",
    gallery: null,
  });
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [location, setLocation] = useState();
  const [galleryImg, setGalleryImg] = useState();

  const [filePreviews, setFilePreviews] = useState({
    partners: [],
    values: [],
    programAreas: [],
    boardMembers: [],
    gallery: null,
    content: null,
    publication: null,
  });
  useEffect(() => {
    if (
      gallery.gallery?.gallery?.length !== null &&
      gallery.gallery?.gallery !== undefined
    ) {
      setTitle(gallery.gallery?.gallery?.title);
      setDescription(gallery.gallery?.gallery?.description);
      setLocation(gallery.gallery?.gallery?.location);
      setGalleryImg(gallery.gallery?.gallery?.gallery);
    }
  }, [gallery.gallery?.gallery]);

  const handleFormSubmit = async (e) => {
    const formData = new FormData();
    formData.append("gallery", image);
    formData.append("title", e.title);
    formData.append("description", e.description);
    formData.append("location", e.location);
    formData.append("id", id);

    dispatch(updateGallery({ formData: formData, id: id }));
  };

  return (
    <form className="mt-16 ml-8 mr-8" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex justify-center mb-8">
        <label htmlFor="formType" className="text-lg" color="primary">
          Gallery
        </label>
      </div>

      <div className="space-y-4">
        <TextField
          variant="outlined"
          name="title"
          value={title}
          {...register(`title`)}
          // onChange={(e) => handleInputChange(e, 0, "gallery")}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          className="my-4"
        />

        <TextField
          // label="Description"
          variant="outlined"
          name="description"
          value={description}
          {...register(`description`)}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
          fullWidth
          className="my-4"
        />

        <TextField
          //   label="Location"
          variant="outlined"
          name="location"
          value={location}
          {...register(`location`)}
          //onChange={(e) => handleInputChange(e, 0, "gallery")}
          onChange={(e) => setLocation(e.target.value)}
          fullWidth
          className="my-4"
        />
        <div className="space-x-4">
          <img
            src={`https://backend.riftvalley.org.et/uploads/${galleryImg}`}
            width={90}
          />
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            name="gallery"
            multiple
            className=" lg:ml-20 ml-10 mt-3"
          />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div>
          <button
            className="mt-8 mb-8 bg-blue-600 px-4 py-2 text-white rounded-md"
            type="submit"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
};
export default UpdateGallery;
