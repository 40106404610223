import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RxAvatar } from "react-icons/rx";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  updateusers,
  singleusers,
} from "../../States/Action/User-Action/UserAction";
import Loading from "../Loading/loading";
import bcrypt from "bcryptjs";
import {
  Container,
  Grid,
  TextField,
  Button,
  Avatar,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const UpdateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userToEdit = location.state?.user;
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(singleusers(id));
  }, []);

  const users = useSelector((state) => state.users);

  const [email, setEmail] = useState("");
  const [full_name, setName] = useState("");
  const [role, setRole] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userToEdit && userToEdit.avatar) {
      setPreview(userToEdit.avatar);
    }
  }, [userToEdit]);

  useEffect(() => {
    if (
      users?.users?.user?.length !== null &&
      users?.users?.user !== undefined
    ) {
      setName(users.users.user.full_name);
      setEmail(users?.users?.user?.email);
      setPhoneNumber(users?.users?.user?.phoneNumber);
      setRole(users?.users?.user?.role);
    }
  }, [users?.users?.user]);

  // const passwordUpdate = bcrypt.compare(users?.users?.user?.password);

  // console.log("current password : ", passwordUpdate);

  //console.log("users data : ", users?.users?.user?.password);

  const handleFileInputChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setAvatar(null);
      return;
    }
    setAvatar(e.target.files[0]);
  };

  const handleSubmit = async (data) => {
    data.preventDefault();

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please try again.");
      return;
    }
    const formData = new FormData();
    dispatch(
      updateusers({
        avatar: avatar,
        full_name: full_name,
        email: email,
        phoneNumber: phoneNumber,
        role: role,
        id: id,
        password: password,
      })
    );
    setTimeout(function () {
      navigate("/users");
    }, 1000);
  };

  return (
    <>
      {users?.users?.user ? (
        <Container
          maxWidth="md"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h4" align="center" gutterBottom>
              Update User
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="full_name"
                    variant="outlined"
                    fullWidth
                    required
                    value={full_name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    variant="outlined"
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    {/* <InputLabel id="role-label">Role</InputLabel> */}
                    <Select
                      labelId="role-label"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      required
                    >
                      <MenuItem value="user">User</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="moderator">Moderator</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="phoneNumber"
                    variant="outlined"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    variant="outlined"
                    label="add new password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} container alignItems="center">
                  <Avatar
                    src={preview}
                    style={{
                      height: "56px",
                      width: "56px",
                      marginRight: "10px",
                    }}
                  >
                    {!preview && <RxAvatar />}
                  </Avatar>
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      backgroundColor: "#87CEEB",
                      color: "white",
                      "&:hover": { backgroundColor: "#2c2a46" },
                    }}
                  >
                    Upload a file
                    <input
                      type="file"
                      name="avatar"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleFileInputChange}
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "#87CEEB", marginTop: "20px" }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default UpdateUser;
